import React, { useState } from 'react';

import "./Auth.css"

function Auth() {
  const [inputs, setInputs] = useState(['', '', '', '', '', '']);
  const inputRefs = Array.from({ length: 6 }, () => React.createRef());

  const handleInputChange = (index, value) => {
    if (value && index < 5) {
      inputRefs[index + 1].current.focus();
    }
    const newInputs = [...inputs];
    newInputs[index] = value;
    setInputs(newInputs);
  }

  const handleKeyDown = (index, e) => {
    if (e.key === 'Backspace' && index > 0 && !inputs[index]) {
      // Handle delete action
      inputRefs[index - 1].current.focus();
    }
  }

  const handlePaste = (index, e) => {
    e.preventDefault();
    const clipboardData = e.clipboardData.getData('text');
    const pasteValues = clipboardData.split('').slice(0, 6);
    
    const newInputs = [...inputs];
    pasteValues.forEach((value, i) => {
      newInputs[index + i] = value;
    });

    setInputs(newInputs);

    // Move focus to the next empty input
    const nextEmptyIndex = newInputs.findIndex((value) => !value);
    if (nextEmptyIndex !== -1) {
      inputRefs[nextEmptyIndex].current.focus();
    }
  }

  const authUser = (e) => {
    e.preventDefault();
    // Your authentication logic here
  }

  return (
    <div className='form-holder'>
      <form onSubmit={authUser} className='login'>
        <h1 className='form-title'>Verification</h1>
        <span>Please check your email</span>
        <div className='form-label verification'>
          {inputs.map((value, index) => (
            <input
              key={index}
              type='text'
              maxLength={1}
              value={value}
              onChange={(e) => handleInputChange(index, e.target.value)}
              onKeyDown={(e) => handleKeyDown(index, e)}
              onPaste={(e) => handlePaste(index, e)}
              ref={inputRefs[index]}
            ></input>
          ))}
        </div>
        <button className='btn primary'>Verify</button>
        <br></br>
      </form>
    </div>
  )
}

export default Auth;
