import React, { useEffect, useState } from 'react'

import "./Index.css"

import Slider from '../../Components/Slider/Slider'

import SecurityIcon from '@mui/icons-material/Security';
import EuroIcon from '@mui/icons-material/Euro';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';

import { Link } from 'react-router-dom';

import { Helmet } from 'react-helmet-async';

import Settings from "../../Components/Settings.json"
import CardComponent from '../../Components/CardComponent/CardComponent';

function Index() {

  const [carList, setCarList] = useState([])

  const [addTextOne, setTextOne] = useState("")
  const [addTextTwo, setTextTwo] = useState("")
  const [addTextThree, setTextThree] = useState("")
  const [tourList, setTourList] = useState([])

  const loadCars = () => {
    fetch(Settings.Domain + Settings.GetCars, {
      method: "GET"
    })
      .then(response => {
        if (!response.ok) {
          new Error("Response was not ok")
        }

        return response.json()
      })
      .then(data => {
        if (data.error) {
          return
        }

        if (!data.response) {
          return
        }

        data.response.cars.forEach(element => {
          element.car_images = JSON.parse(element.car_images)
        })

        var count = data.response.cars.length
        var carList = []

        for (let index = 0; index < count; index++) {
          var car = data.response.cars[index];
          var car_data = data.response.car_data[index];

          car_data.car_release = JSON.parse(car_data.car_release)

          carList.push({
            id: car.car_data_id,
            name: car.car_name,
            price: car.car_price,
            image: Settings.Domain + "/images/" + car.car_images[0],
            brand: car_data.brand,
            seats: car_data.car_seats,
            automatic: car_data.car_automatic,
            car_release: car_data.car_release,
            car_luggage: car_data.car_luggage,
            burn: car_data.burn,
            engine: car_data.car_engine,
            feul: car_data.car_fuel,
            engine: car_data.car_engine,
            characteristics: car_data.car_characteristics
          })
        }
        setCarList(carList)
      })
      .catch(error => {
        console.log(error);
      })

  }

  const loadText = () => {
    fetch(Settings.Domain + Settings.GetText, {
      method: "GET"
    })
      .then(response => {
        if (!response.ok) {
          throw Error("Response was not ok")
        }

        return response.json()
      })
      .then(data => {
        if (data.success) {
          const text1 = data.response[0].text
          const text2 = data.response[1].text
          const text3 = data.response[2].text

          setTextOne(text1)
          setTextTwo(text2)
          setTextThree(text3)
        }
      })
  }

  const loadExpeditions = () => {
    fetch(Settings.Domain + Settings.GetExpeditions, {
      method: "GET",
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setTourList(data.response)
        } else if (data.error) {
          //opensnackbar("error", data.error)
        } else {
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  useEffect(() => {
    loadText()
    loadCars()
    loadExpeditions()
  }, [])

  return (
    <div style={{maxWidth: 1536, margin: "auto"}}>
      <Helmet>
        <title>Let's Ride Georgia</title>
        <meta name="keywords"
          content="car rental, rent a car, vehicle rental, LetsRide, transportation, travel, affordable car rental, letsride, let's ride, Georgia"></meta>
      </Helmet>
      <div className='container'>
        <Slider></Slider>
      </div>
      <div className='container'>
        <div className=''>
          <div className='text-center'>
            <p className='index-page-secition-title'>WHY US?</p>
          </div>
          <div className='card-info-holder'>
            <div className='card'>
              <DirectionsCarIcon></DirectionsCarIcon>
              <p>{addTextOne}</p>
            </div>
            <div className='card'>
              <EuroIcon></EuroIcon>
              <p>{addTextTwo}</p>
            </div>
            <div className='card'>
              <SecurityIcon></SecurityIcon>
              <p>{addTextThree}</p>
            </div>
          </div>
        </div>
      </div>
      <div className='container'>
        <div className=''>
          <div className='text-center'>
            <p className='index-page-secition-title'>MOST PUPULAR CARS</p>
          </div>
          <br></br>
          <br></br>
          <div className='card-car-holder'>
            {carList.map((car, index) => (
              <CardComponent car={car} key={index} />
            ))}
          </div>
        </div>
      </div>
      <div className='container'>
        <div className=''>
          <div className='text-center'>
            <p className='index-page-secition-title'>EXPEDITIONS</p>
          </div>
          <br></br>
          <br></br>
          <div className='container' style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            {tourList.map((tour, index) => (
              <Link key={index} className='tour-element' to={"/tourList/" + tour.id}>
                <img style={{ backgroundImage: `url(${Settings.Domain + "/images/" + tour.banner})`, backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "cover" }}></img>
                <div className='shadow'></div>
                <p className='tour-title'>{tour.title}</p>
              </Link>
            ))}
          </div>
        </div>
      </div>
      <div className='container'>
        <div className="trustpilot-widget" data-locale="en-US" data-template-id="56278e9abfbbba0bdcd568bc" data-businessunit-id="655b546f0e2b864120525bd4" data-style-height="52px" data-style-width="100%">
          <a href="https://www.trustpilot.com/review/letsride.ge" target="_blank" rel="noopener">Trustpilot</a>
        </div>
      </div>
    </div>
  )
}

export default Index