import React, { useState } from 'react'

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TabPanel from "@mui/lab/TabPanel"
import TabContext from '@mui/lab/TabContext';

import AddExpedition from './AddExpedition/AddExpedition';
import EditExpedtioion from './EditExpedition/EditExpedtioion';
import DeleteExpedition from './DeleteExpedition/DeleteExpedition';

function Expedition() {

    const [value, setValue] = useState('1');

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    return (
        <Box sx={{ width: "100%", bgcolor: 'background.paper', margin: "0 0 0 10px" }}>
            <TabContext value={value}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                >
                    <Tab label="Add" value={"1"} />
                    <Tab label="Edit" value={"2"} />
                    <Tab label="Remove" value={"3"} />
                </Tabs>
                <TabPanel value="1">
                    <AddExpedition></AddExpedition>
                </TabPanel>
                <TabPanel value="2">
                    <EditExpedtioion></EditExpedtioion>
                </TabPanel>
                <TabPanel value="3">
                    <DeleteExpedition></DeleteExpedition>
                </TabPanel>
            </TabContext>
        </Box>
    )
}

export default Expedition