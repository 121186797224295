import MUIRichTextEditor from 'mui-rte';
import React, { useEffect, useRef, useState } from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import "./AddExpedition.css";
import { TextField, Button, Autocomplete } from '@mui/material';
import { convertToRaw } from 'draft-js';
import Settings from "../../../Settings.json"
import { useAuth } from '../../../AuthProvider/AuthProvider';
import CustomizedSnackbars from '../../../Snackbar/Snackbar';

function AddExpedition() {

  const { user } = useAuth()

  const [responseMessage, setResponseMessage] = useState()
  const [messageType, setMessageType] = useState()
  const [snackBarOpen, setSnackBarOpen] = useState(false)
  const [key, setKey] = useState(0)

  const opensnackbar = (type, message) => {
    setSnackBarOpen(true)
    setMessageType(type)
    setResponseMessage(message)
    setKey(key + 1)
  }

  const [image, setImage] = useState(null);
  const [description, setDescription] = useState(null)
  const [price, setPrice] = useState(0)
  const [people, setPeople] = useState(0)
  const [expeditionTags, setExpeditionTags] = useState([])
  const [expeditionTitle, setExpeditionTitle] = useState()

  const handleImageChange = (e) => {
    const file = e.target.files[0]
    setImage(file)
  };

  const handleEditorChange = (state) => {
    if (state) {
      const contentState = state.getCurrentContent();
      const rawContentState = convertToRaw(contentState);
      setDescription(rawContentState);
    }
  };


  const saveExpedition = () => {
    const formData = new FormData();

    formData.append('image', image);
    formData.append('price', price);
    formData.append('description', JSON.stringify(description));
    formData.append('people', people);
    formData.append('token', user.token);
    formData.append('tags', JSON.stringify(expeditionTags))
    formData.append('title', expeditionTitle)

    fetch(Settings.Domain + Settings.AddExpeditions, {
      method: "POST",
      body: formData,
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          opensnackbar("Success", data.response)
        } else if (data.error) {
          opensnackbar("error", data.error)
        } else {
          // Handle other cases
        }
      })
      .catch(error => {
        console.log(error);
        //opensnackbar("error", error)
      });
  };


  return (
    <div>
      <div className="add-image" style={{ position: "relative", overflow: "hidden" }}>
        {image && <img src={URL.createObjectURL(image)} className='uploaded-image' alt="Uploaded" />}
        <input
          type='file'
          className='expedition-upload-input'
          onChange={handleImageChange}
        />
        <label htmlFor='expedition-upload-input'>
          <CloudUploadIcon className='image-upload-svg' />
        </label>
      </div>
      <div className='expedition-description'>
        <MUIRichTextEditor
          id='expedition-description'
          label="Type something here..."
          onChange={handleEditorChange}
          inlineToolbar={true}
          maxLength={500}
          style={{ height: 300 }}
        />
      </div>
      <div className='details-expedition'>
        <TextField
          type="text"
          label="Title"
          value={expeditionTitle}
          onChange={(e) => { setExpeditionTitle(e.target.value) }}
        />
                <br></br>
        <br></br>
        <TextField
          value={price}
          onChange={(e) => { setPrice(e.target.value) }}
          label="Price"
        />
        <br></br>
        <br></br>
        <TextField
          type="number"
          label="People"
          value={people}
          onChange={(e) => { setPeople(e.target.value) }}
        />
        <br></br>
        <br></br>
        <Autocomplete
          multiple
          limitTags={2}
          id="multiple-limit-tags"
          freeSolo
          fullWidth
          options={expeditionTags}
          renderInput={(params) => (
            <TextField {...params} label="Tags" placeholder="Favorites" />
          )}
          onChange={(event, value) => {
            setExpeditionTags(value)
          }}
          sx={{ width: '500px' }}
        />
      </div>
      <div style={{ display: "flex" }}>
        <Button color="primary" variant="outlined" style={{ margin: "auto" }} onClick={saveExpedition}>Save</Button>
      </div>
      <CustomizedSnackbars messageType={messageType} message={responseMessage} openSnackbar={snackBarOpen} key={key} />
    </div>
  );
}

export default AddExpedition;
