import React, { useEffect, useState } from 'react';

import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import TimeToLeaveIcon from '@mui/icons-material/TimeToLeave';
import EventSeatIcon from '@mui/icons-material/EventSeat';
import LuggageIcon from '@mui/icons-material/Luggage';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import ShutterSpeedIcon from '@mui/icons-material/ShutterSpeed';
import MinorCrashIcon from '@mui/icons-material/MinorCrash';

import Settings from "../../../Settings.json"

import "./AddCar.css";
import { FormControl, Select, Switch, TextField, MenuItem, Box, Button, Autocomplete } from '@mui/material';
import { useAuth } from '../../../AuthProvider/AuthProvider';
import CustomizedSnackbars from '../../../Snackbar/Snackbar';
import MUIRichTextEditor from 'mui-rte';
import { convertToRaw } from 'draft-js';

function AddCar() {

    const [responseMessage, setResponseMessage] = useState()
    const [messageType, setMessageType] = useState()
    const [snackBarOpen, setSnackBarOpen] = useState(false)
    const [key, setKey] = useState(0)


    const opensnackbar = (type, message) => {
        setSnackBarOpen(true)
        setMessageType(type)
        setResponseMessage(message)
        setKey(key + 1)
    }

    const [hey, sethey] = useState("dwadawdawdaw")

    const { user, login, logout } = useAuth()

    const [selectedImages, setSelectedImages] = useState([]);

    const [carCharacteristics, setCarCharacteristics] = useState("");
    const [carEngine, setCarEngine] = useState(4.0)
    const [carFeul, setCarFuel] = useState("Diesel")

    const [carBrands, setCarBrands] = useState([])
    const [carBrand, setCarBrand] = useState(1)

    const [releaseYearsList, setReleaseYearsList] = useState([])


    const [previewImage, setPreviewImage] = useState(null)
    const [carName, setCarName] = useState("")
    const [pricePerDay, setPricePerDay] = useState()
    const [carTags, setCarTags] = useState([])
    const [carBurn, setCarBurn] = useState([])

    const [carDescription, setCarDescription] = useState("")


    const [carAutomatic, setCarAutomatic] = useState(false)

    const [carSeats, setCarSeats] = useState(0)
    const [carLuggage, setCarLuggage] = useState(0)
    const [carFuelsList, setCarFuelsList] = useState([])

    const [releaseYear, setReleaseYear] = useState(2012)
    const [endYear, setEndYear] = useState(new Date().getFullYear())

    const [carPlaces, setCarPlaces] = useState([])

    const handleImageChange = (event) => {
        const files = Array.from(event.target.files);
        setSelectedImages([...selectedImages, ...files]);
        setPreviewImage(files[0])
    };

    const handleCarPlaceChange = (index, newValue) => {
        const updatedCarPlaces = [...carPlaces]; // Create a copy of the carPlaces array
        updatedCarPlaces[index - 1].amount = newValue; // Update the amount of the specified place in the copy
        setCarPlaces(updatedCarPlaces); // Update the state with the modified copy
    };

    const handleEditorChange = (state) => {
        if (state) {
          const contentState = state.getCurrentContent();
          const rawContentState = convertToRaw(contentState);
          setCarDescription(rawContentState);
        }
      };

    const saveCar = () => {
        const carData = new FormData();

        carData.append('car_name', carName);
        carData.append('car_description', JSON.stringify(carDescription));
        carData.append('price_per_day', pricePerDay);
        carData.append('car_automatic', carAutomatic);
        carData.append('car_seats', carSeats);
        carData.append('engine', carEngine);
        carData.append('characteristics', carCharacteristics);
        carData.append('car_feul', carFeul);
        carData.append('car_luggage', carLuggage);
        carData.append('car_release', JSON.stringify([releaseYear, endYear]));
        carData.append('car_places', JSON.stringify(carPlaces));
        carData.append('token', user.token);
        carData.append('brand', carBrand);
        carData.append('tags', JSON.stringify(carTags));
        carData.append('burn', carBurn);

        // Append images
        for (const image of selectedImages) {
            carData.append('images', image);
        }

        fetch(Settings.Domain + Settings.AddCar, {
            method: 'POST',
            body: carData,
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    opensnackbar("success", "Car added successfully")
                } else if (data.error) {
                    opensnackbar("error", data.error)
                }
            })
            .catch((error) => {
                opensnackbar("error", error)
            });
    };

    const removeImage = (index) => {
        const updatedImages = [...selectedImages];
        updatedImages.splice(index, 1);
        setSelectedImages(updatedImages);
        if (index === 0 && updatedImages.length > 0) {
            setPreviewImage(updatedImages[0]);
        } else {
            setPreviewImage(null);
        }
    };


    useEffect(() => {
        const start_year = 2012
        const end_year = new Date().getFullYear()

        for (let year = start_year; year <= end_year; year++) {
            releaseYearsList.push(year)
        }

        fetch(Settings.Domain + Settings.GetPlaces, {
            method: "GET"
        })
            .then(response => {
                return response.json()
            })
            .then(data => {
                if (data.response) {

                    data.response.forEach(element => {
                        element.amount = 0
                    })

                    setCarPlaces(data.response)
                }
            })

        fetch(Settings.Domain + Settings.GetBrands, {
            method: "GET"
        })
            .then(response => {

                return response.json()
            })
            .then(data => {
                if (data.response) {
                    setCarBrands(data.response)
                }
            })


        fetch(Settings.Domain + Settings.LoadFeuls, {
            method: "GET"
        })
            .then(response => {
                return response.json()
            })
            .then(data => {
                if (data.success) {
                    setCarFuelsList(data.response)
                }
            })
    }, [])


    return (
        <div className='container' style={{ margin: 20, height: "fit-content" }}>
            <div className='image-uploader'>
                <div className='upload-image-holder'>
                    <input
                        type="file"
                        accept="image/*"
                        multiple
                        onChange={handleImageChange}
                        className='image-input'
                    />
                    <CloudUploadIcon className='image-upload-svg'></CloudUploadIcon>
                    {previewImage && (
                        <img src={URL.createObjectURL(previewImage)}></img>
                    )}
                </div>

                {selectedImages.length > 0 && (
                    <div className="image-preview">
                        {selectedImages.map((image, index) => (
                            <div key={index} className="image-preview-item">
                                <img
                                    src={URL.createObjectURL(image)}
                                    alt={`Selected ${index}`}
                                    style={{ maxWidth: "100px", margin: "5px" }}
                                    onClick={() => {
                                        setPreviewImage(image);
                                    }}
                                />
                                <button
                                    className="remove-image-button"
                                    onClick={() => {
                                        removeImage(index);
                                    }}
                                >
                                    <DeleteIcon></DeleteIcon>
                                </button>
                            </div>
                        ))}
                    </div>
                )}
                <div className='car-rent-information-add'>
                    <TextField
                        value={carName}
                        onChange={(e) => { setCarName(e.target.value) }}
                        label="Car name"
                    />
                    <TextField
                        type="number"
                        label="Price"
                        value={pricePerDay}
                        onChange={(e) => { setPricePerDay(e.target.value) }}
                    />
                    <TextField
                        value={carBurn}
                        onChange={(e) => { setCarBurn(e.target.value) }}
                        label="Burn"
                    />
                    <Select
                        fullWidth
                        value={carBrand}
                        onChange={(e) => {
                            setCarBrand(e.target.value)
                        }}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                    >
                        {
                            carBrands.map((brand, index) => (
                                <MenuItem value={brand.id}>{brand.brand_name}</MenuItem>
                            ))
                        }
                    </Select>
                    {
                        carPlaces.map((value, index) => (
                            <TextField
                                key={value.id}
                                label={value.place_name}
                                type="number"
                                value={value.amount}
                                onChange={(e) => { handleCarPlaceChange(value.id, e.target.value) }}
                            />
                        ))
                    }

                    <Box
                        sx={{
                            width: '100%',
                        }}
                    >
                        <MUIRichTextEditor
                            id='expedition-description'
                            label="Type something here..."
                            onChange={handleEditorChange}
                            inlineToolbar={true}
                            maxLength={1024}
                            style={{ height: 300}}
                        />
                    </Box>
                    <Autocomplete
                        multiple
                        limitTags={2}
                        id="multiple-limit-tags"
                        freeSolo
                        options={carTags}
                        renderInput={(params) => (
                            <TextField {...params} label="Tags" placeholder="Keywords" />
                        )}
                        onChange={(event, value) => {
                            setCarTags(value)
                        }}
                        sx={{ width: '500px' }}
                    />
                </div>
                <div className='car-data-list-admin' style={{ background: "transparent", boxShadow: "none" }}>
                    <div className='car-detail-data'>
                        <div className='icon'>
                            <LocalGasStationIcon></LocalGasStationIcon>
                            <span>Fuel</span>
                        </div>
                        <Select
                            fullWidth
                            value={carFeul}
                            id="outlined-number"
                            type="text"
                            InputLabelProps={{
                                shrink: true,
                            }}

                            style={{
                                width: 150
                            }}
                            onChange={(e) => {
                                setCarFuel(e.target.value)
                            }}
                        >
                            {
                                carFuelsList.map((fuel, index) => (
                                    <MenuItem value={fuel.id}>{fuel.fuel_name}</MenuItem>
                                ))
                            }
                        </Select>
                    </div>
                    <div className='car-detail-data'>
                        <div className='icon'>
                            <TimeToLeaveIcon></TimeToLeaveIcon>
                            <span>Automatic</span>
                        </div>
                        <Switch
                            checked={carAutomatic}
                            onChange={(e) => { setCarAutomatic(!carAutomatic) }}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    </div>
                    <div className='car-detail-data'>
                        <div className='icon'>
                            <EventSeatIcon></EventSeatIcon>
                            <span>Seats</span>
                        </div>
                        <TextField
                            value={carSeats}
                            id="outlined-number"
                            type="number"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                min: 0,
                                inputMode: 'numeric',
                                pattern: '[0-9]*',
                            }}
                            style={{
                                height: 80,
                                width: 150
                            }}
                            onChange={(e) => {
                                setCarSeats(e.target.value)
                            }}
                        />
                    </div>
                    <div className='car-detail-data'>
                        <div className='icon'>
                            <ShutterSpeedIcon></ShutterSpeedIcon>
                            <span>Engine</span>
                        </div>
                        <TextField
                            value={carEngine}
                            id="outlined-number"
                            type="number"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                min: 0,
                                inputMode: 'numeric',
                                pattern: '[0-9]*',
                            }}
                            style={{
                                height: 80,
                                width: 150
                            }}
                            onChange={(e) => {
                                setCarEngine(e.target.value)
                            }}
                        />
                    </div>
                    <div className='car-detail-data'>
                        <div className='icon'>
                            <MinorCrashIcon></MinorCrashIcon>
                            <span>Characteristics</span>
                        </div>
                        <TextField
                            value={carCharacteristics}
                            id="outlined-number"
                            type="string"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            style={{
                                height: 80,
                                width: 150
                            }}
                            onChange={(e) => {
                                setCarCharacteristics(e.target.value)
                            }}
                        />
                    </div>
                    <div className='car-detail-data'>
                        <div className='icon'>
                            <LuggageIcon></LuggageIcon>
                            <span>Luggage</span>
                        </div>
                        <TextField
                            value={carLuggage}
                            id="outlined-number"
                            type="number"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                min: 0,
                                inputMode: 'numeric',
                                pattern: '[0-9]*',
                            }}
                            style={{
                                height: 80,
                                width: 150
                            }}
                            onChange={(e) => {
                                setCarLuggage(e.target.value)
                            }}
                        />
                    </div>
                    <div className='car-detail-data'>
                        <div className='icon'>
                            <CalendarMonthIcon></CalendarMonthIcon>
                            <span>Release</span>
                        </div>
                        <div className='year-selecttor'>
                            <FormControl>
                                <Select style={{ width: 150 }} value={releaseYear} onChange={(e) => { setReleaseYear(e.target.value) }}>
                                    {releaseYearsList.map((year) => (
                                        <MenuItem key={year} value={year}>
                                            {year}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl>
                                <Select style={{ width: 150 }} value={endYear} onChange={(e) => { setEndYear(e.target.value) }}>
                                    {releaseYearsList.map((year) => (
                                        <MenuItem key={year} value={year}>
                                            {year}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                </div>
                <Button color="primary" variant="outlined" style={{ margin: "auto", width: 250, height: 50 }} onClick={saveCar}>Save</Button>
            </div>
            <CustomizedSnackbars messageType={messageType} message={responseMessage} openSnackbar={snackBarOpen} key={key} />
        </div>
    );
}

export default AddCar;
