import React, { useEffect, useState } from 'react'
import { Autocomplete, Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Pagination, Skeleton, Slider, Switch, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import SortIcon from '@mui/icons-material/Sort';

import "./CarsList.css"
import { Link } from 'react-router-dom';

import Settings from "../../Components/Settings.json"
import CardComponent from '../../Components/CardComponent/CardComponent';
import { Helmet } from 'react-helmet-async';

function CarsList() {
    const [carList, setCarlist] = useState([])

    const [carBrands, setCarBrands] = useState([])
    const [carBrandFilter, setCarBrandFilter] = useState([])

    const [filteredCars, setFilteredCars] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [priceRangeFilter, setPriceRangeFilter] = useState([0, 100]);
    const [minSeats, setMinSeats] = useState(10)

    const [filterYear, setFilterYear] = useState([2000, new Date().getFullYear()])
    const [carListLoaded, setCarListLoaded] = useState([])

    const maxPrice = 2500

    const [page, setPage] = useState(1);
    const itemsPerPage = 9;

    const [state, setState] = useState({
        right: false,
    });

    const sortCars = (data) => {

        var carData = []

        data.car_data.map((car, index) => {

            const carDataList = data.cars.find((a) => { return a.car_data_id === car.car_id })

            const images = JSON.parse(carDataList.car_images)

            console.log(1);

            carData.push({
                id: car.car_id,
                name: carDataList.car_name,
                price: carDataList.car_price,
                image: Settings.Domain + "/images/" + images[0],
                brand: car.brand,
                feul: car.car_fuel,
                seats: car.car_seats,
                car_luggage: car.car_luggage,
                car_release: JSON.parse(car.car_release),
                automatic: car.car_automatic,
                description: carDataList.car_description.slice(0, 128) + "...",
                burn: car.burn,
                engine: car.car_engine,
                characteristics: car.car_characteristics,
                show: true
            })
        })

        setCarlist(carData)
    }


    const loadCars = () => {

        fetch(Settings.Domain + Settings.GetCars, {
            method: "GET"
        })
            .then(response => {
                if (!response.ok) {
                    new Error("Response was not ok")
                }

                return response.json()
            })
            .then(data => {
                if (data.error) {
                    return
                }

                if (!data.response) {
                    return
                }

                sortCars(data.response)
            })
            .catch(error => {
                console.log(error);
            })

    }


    const applyFilters = () => {
        const filtered = carList.filter(car => {
            const nameMatch = car.name.toLowerCase().includes(searchQuery.toLowerCase());
    
            car.show = nameMatch;

            return car.show;
        });
    
        setFilteredCars(filtered);
    
        const startIndex = (page - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
    
        setCarListLoaded(filtered.slice(startIndex, endIndex));
    };
    


    const checkPriceRange = (price) => {

        const priceRange = [((priceRangeFilter[0] / 100) * maxPrice), ((priceRangeFilter[1] / 100) * maxPrice)].sort((a, b) => a < b)

        const min = priceRange[0]
        const max = priceRange[1]

        return price >= min && price <= max;
    }

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
            display={"flex"}
            justifyContent={"center"}
            role="presentation"
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List
                style={{ width: "80%", marginTop: 40 }}
            >
                <span>
                    {(() => {
                        const priceRange = [((priceRangeFilter[0] / 100) * maxPrice), ((priceRangeFilter[1] / 100) * maxPrice)].sort((a, b) => a < b);
                        const min = priceRange[0];
                        const max = priceRange[1];
                        return `Price: ${Math.floor(min)} - ${Math.floor(max)}`;
                    })()}
                </span>

                <Slider
                    getAriaLabel={() => 'Price range'}
                    value={priceRangeFilter}
                    onChange={(e, newValue) => {
                        setPriceRangeFilter(newValue)
                    }}
                    valueLabelDisplay="auto"
                    getAriaValueText={() => {
                        return "Price Range"
                    }}
                />

                <br></br>
                <br></br>

                <span>
                    {(() => {
                        const priceRange = filterYear.sort((a, b) => a < b);
                        const min = priceRange[0];
                        const max = priceRange[1];
                        return `Year: ${Math.floor(min)} - ${Math.floor(max)}`;
                    })()}
                </span>

                <Slider
                    getAriaLabel={() => 'Price range'}
                    min={2000}
                    max={new Date().getFullYear()}
                    value={filterYear}
                    onChange={(e, newValue) => {
                        setFilterYear(newValue)
                    }}
                    valueLabelDisplay="off"
                    getAriaValueText={() => {
                        return "Price Range"
                    }}
                />

                <br></br>
                <br></br>

                <Autocomplete
                    multiple
                    options={carBrands}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.brand_name}
                    renderOption={(props, option, { selected }) => (
                        <li {...props} value={option.id} key={option.id}>
                            {option.brand_name}
                        </li>
                    )}
                    style={{ width: "100%" }}
                    renderInput={(params) => (
                        <TextField {...params} label="Models" placeholder="Car Models" />
                    )}
                    onChange={(event, value) => {
                        setCarBrandFilter(value);
                    }}
                    value={carBrandFilter} // Set the value from your state
                />



                <br></br>
                <br></br>

                <span>Seats {minSeats}</span>
                <Slider
                    max={10}
                    min={0}
                    valueLabelDisplay="on"
                    value={minSeats}
                    onChange={(e) => {
                        setMinSeats(e.target.value)
                    }}
                />
            </List>
        </Box>
    );

    useEffect(() => {
        loadCars();

        fetch(Settings.Domain + Settings.GetBrands, {
            method: "GET"
        })
            .then(response => {

                if (!response.ok) {
                    new Error("Response was not ok")
                    return
                }

                return response.json()
            })
            .then(data => {
                if (data.response) {
                    setCarBrands(data.response)
                }
            })
    }, []);

    useEffect(() => {
        applyFilters()
    }, [carList, page])

    useEffect(() => {
        applyFilters();
    }, [searchQuery, priceRangeFilter, carBrandFilter, filterYear, minSeats]);

    return (
        <div className='container car' style={{ margin: "200px auto 200px auto", maxWidth: 1536 }}>
            <Helmet>
                <title>Let's Ride Cars In Georgia</title>
                <meta name="keywords"
                        content="cars, car list, list, car, letsirde, let's ride, rent, explore, georgia"></meta>
            </Helmet>
            <div className='filter'>
                <TextField
                    onChange={(e) => { setSearchQuery(e.target.value) }}
                    value={searchQuery}
                    label="Search"
                    fullWidth
                    variant="filled"
                />
                {/* <React.Fragment>
                    <Button 
                        onClick={toggleDrawer("right", true)}
                        variant="outlined"
                        sx={{margin: "0 0 0 10px"}}
                        >
                        <SortIcon></SortIcon>
                    </Button>
                    <Drawer
                        anchor={"right"}
                        open={state["right"]}
                        onClose={toggleDrawer("right", false)}
                    >
                        {list("right")}
                    </Drawer>
                </React.Fragment> */}
            </div>
            <br></br>
            <div className='car-list-holder'>
                {carListLoaded.length > 0 ? (
                    carListLoaded.map((car, index) => {
                        if (car.show) {
                            return (
                                <CardComponent car={car} key={index} />
                            )
                        }
                    })
                ) : (
                    Array(30).fill().map((_, index) => (
                        <Skeleton key={index} animation="wave" variant="rectangular" height={400}/>
                    ))
                )}
            </div>
            <Pagination
                count={Math.ceil(filteredCars.length / itemsPerPage)} // Calculate the number of pages based on the filtered cars
                page={page}
                onChange={(event, value) => {
                    setPage(value)
                }}
                color="primary"
                style={{ margin: "20px auto", width: "fit-content" }}
            />
        </div>
    )
}

export default CarsList