import React, { useState } from 'react'

import { Autocomplete, Button, TextField } from '@mui/material'
import CustomizedSnackbars from '../../../Snackbar/Snackbar'

import Settings from "../../../Settings.json"

import "./EditExpedtinion.css"
import MUIRichTextEditor from 'mui-rte'
import { convertFromRaw, convertToRaw } from 'draft-js'
import { useAuth } from '../../../AuthProvider/AuthProvider'

const emptyContentState = {
    blocks: [
        {
            key: '2c6kf',
            text: '',
            type: 'unstyled',
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
        },
    ],
    entityMap: {},
};


function EditExpedtioion() {

    const { user } = useAuth()

    const [showDetails, setShowDetails] = useState(false)

    const [expeditionID, setExpeditionID] = useState(0)

    const [descriptionToSave, setDescriptionToSave] = useState(emptyContentState);
    const [descriptionToShow, setDescriptionToShow] = useState(emptyContentState); 

    const [price, setPrice] = useState(0)
    const [people, setPeople] = useState(0)
    const [expeditionTags, setExpeditionTags] = useState([])
    const [expeditionTitle, setExpeditionTitle] = useState()

    const [responseMessage, setResponseMessage] = useState()
    const [messageType, setMessageType] = useState()
    const [snackBarOpen, setSnackBarOpen] = useState(false)
    const [key, setKey] = useState(0)

    const opensnackbar = (type, message) => {
        setSnackBarOpen(true)
        setMessageType(type)
        setResponseMessage(message)
        setKey(key + 1)
    }

    const handleEditorChange = (state) => {
        if (state) {
            const contentState = state.getCurrentContent();

            setDescriptionToSave(convertToRaw(contentState));
            console.log(contentState)
            setDescriptionToSave(convertToRaw(contentState))
        }
    };



    const saveExpedition = () => {
        fetch(Settings.Domain + Settings.SaveExpedition, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                id: expeditionID,
                description: JSON.stringify(descriptionToSave),
                people: people,
                price: price,
                token: user.token,
                title: expeditionTitle,
                tags: JSON.stringify(expeditionTags)
            })
        })
            .then(response => {
                return response.json()
            })
            .then(data => {
                if (data.success) {
                    opensnackbar("success", data.response)
                }
                if (data.error) {
                    opensnackbar("error", data.error)
                }
            })
            .catch(error => {
                console.log(error);
            })
    }


    const loadExpedition = () => {
        fetch(Settings.Domain + Settings.GetExpedition, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                id: expeditionID
            })
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    const response = data.response;

                    setPrice(response.price);
                    setPeople(response.people);
                    setExpeditionTags(response.tags);
                    setExpeditionTitle(response.title);

                    if (response && response.tags) {

                        var tag = JSON.parse(response.tags)

                        setExpeditionTags(tag);
                    } else {
                        setExpeditionTags([]);
                    }

                    try {
                        // Validating JSON before parsing
                        const isValidJSON = isJSON(response.description);
                        if (isValidJSON) {
                            const contentState = JSON.parse(response.description);

                            setDescriptionToShow(contentState);

                            setShowDetails(true);
                            opensnackbar("success", "Data loaded");
                        } else {
                            opensnackbar("error", "Invalid JSON format");
                        }
                    } catch (error) {
                        console.error('Error parsing JSON:', error);
                        opensnackbar("error", "Invalid data format");
                    }
                }
                if (data.error) {
                    opensnackbar("error", data.error);
                }
            })
            .catch(error => {
                console.log(error);
            });
    };


    const isJSON = (str) => {
        try {
            JSON.parse(str);
            return true;
        } catch (error) {
            return false;
        }
    };





    return (
        <div className='container' style={{ margin: 20, height: "fit-content" }}>
            {showDetails === true ? (
                <div>
                    <div className='expedition-description'>
                        <MUIRichTextEditor
                            id='expedition-description'
                            label="Type something here..."
                            onChange={handleEditorChange}
                            inlineToolbar={true}
                            maxLength={500}
                            style={{ height: 300 }}
                            value={JSON.stringify(descriptionToShow)}
                        />

                    </div>
                    <div className='details-expedition'>
                        <TextField
                            type="text"
                            label="Title"
                            value={expeditionTitle}
                            onChange={(e) => { setExpeditionTitle(e.target.value) }}
                        />
                        <br></br>
                        <br></br>
                        <TextField
                            value={price}
                            onChange={(e) => { setPrice(e.target.value) }}
                            label="Price"
                        />
                        <br></br>
                        <br></br>
                        <TextField
                            type="number"
                            label="People"
                            value={people}
                            onChange={(e) => { setPeople(e.target.value) }}
                        />
                    </div>
                    <br></br>
                    <br></br>
                    <Autocomplete
                        multiple
                        limitTags={2}
                        id="multiple-limit-tags"
                        freeSolo
                        fullWidth
                        options={expeditionTags}
                        value={expeditionTags}
                        renderInput={(params) => (
                            <TextField {...params} label="Tags" placeholder="Keywords" />
                        )}
                        onChange={(event, value) => {
                            setExpeditionTags(value)
                        }}
                        sx={{ width: '500px' }}
                    />

                    <button className='btn primary' style={{ margin: "20px" }} onClick={saveExpedition}>Save</button>
                </div>
            ) : (
                <div className='search-car-label'>
                    <TextField
                        fullWidth
                        label="Expedition ID"
                        value={expeditionID}
                        onChange={(e) => { setExpeditionID(e.target.value) }}
                    />
                    <br></br>
                    <Button color="primary" variant="outlined" style={{ margin: "auto", width: "250px", height: "50px" }} onClick={loadExpedition}>Search</Button>
                </div>
            )}
            <CustomizedSnackbars messageType={messageType} message={responseMessage} openSnackbar={snackBarOpen} key={key} />
        </div>
    )
}

export default EditExpedtioion