import React from 'react'

function PageNotFound() {
  return (
    <div style={{height: "100vh", widows: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
        <p style={{fontSize: "46px"}}>404 - Page Not Found</p>
    </div>
  )
}

export default PageNotFound