import React from 'react'

import Whatsapp from './Whatsapp/Whatsapp'

import "./SocialMedia.css"

function SocialMedia() {
  return (
    <div className='socialMedia-holder'>
        <Whatsapp></Whatsapp>
    </div>
  )
}

export default SocialMedia