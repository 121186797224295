import React, { useEffect, useState } from 'react'

import "./TourList.css"
import { useParams } from 'react-router-dom'
import MUIRichTextEditor from 'mui-rte'
import Editor from '../../Components/TextEditor/Editor'
import { Paper } from '@mui/material'
import Settings from "../../Components/Settings.json"

import Whatsapp from "../../Components/SocialMedia/Whatsapp/Whatsapp"
import { Helmet } from 'react-helmet-async'

function TourList() {

    const { id } = useParams()

    const [tourData, setTourData] = useState({})

    const [tags, setTags] = useState()

    const loadTour = () => {
        fetch(Settings.Domain + Settings.GetExpedition, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                id: id
            })
        })
            .then(response => {
                return response.json()
            })
            .then(data => {
                if (data.success) {
                    setTourData(data.response)

                    if (data.response.tags) {
                        const tags = JSON.parse(data.response.tags)
    
                        setTags(tags.join(','));
                    }
                }

                if (data.error) {

                }
            })
            .catch(error => {
                console.log(error);
            })
    }

    useEffect(() => {
        loadTour()
    }, [])

    return (
        <div className='container' style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", margin: "200px 0 0 0" }}>
            <Helmet>
                <title>Let's Ride Expedition</title>
                <meta name="keywords" content={tags}></meta>
            </Helmet>
            <div className='tour-element'>
                <img style={{ backgroundImage: `url(${Settings.Domain + "/images/" + tourData.banner})`, backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "cover" }}></img>
                <div className='shadow'></div>
                <p className='tour-title'>{tourData.title}</p>
            </div>
            <div className='tour-element information'>
                <div className='tour-info-header'>
                    <span className='info-name'>Name: </span>
                    <span className='info-value'>{tourData.title}</span>
                </div>
                <div className='tour-info-header'>
                    <span className='info-name'>Price: </span>
                    <span className='info-value'>{tourData.price} $</span>
                </div>
                <div className='tour-info-header'>
                    <span className='info-name'>People: </span>
                    <span className='info-value'>{tourData.people}</span>
                </div>
            </div>
            <div className='tour-element information'>
                <MUIRichTextEditor
                    id='description'
                    defaultValue={tourData.description}
                    readOnly
                    toolbar={false}
                ></MUIRichTextEditor>
            </div>

            <div style={{ margin: "50px 0" }}>
                <Whatsapp></Whatsapp>
            </div>
        </div>
    )
}

export default TourList