import React, { useEffect, useState } from 'react'

import { Link } from 'react-router-dom'
import Settings from "../../Components/Settings.json"
import { useAuth } from '../../Components/AuthProvider/AuthProvider'


import CustomizedSnackbars from "../../Components/Snackbar/Snackbar"

function ForgotPassword() {


    const { user, login } = useAuth()

    const [email, setEmail] = useState("")

    const [responseMessage, setResponseMessage] = useState()
    const [messageType, setMessageType] = useState()
    const [snackBarOpen, setSnackBarOpen] = useState(false)
    const [key, setKey] = useState(0)

    const [delived, setDelivered] = useState(false)

    const opensnackbar = (type, message) => {
        setSnackBarOpen(true)
        setMessageType(type)
        setResponseMessage(message)
        setKey(key + 1)
    }

    useEffect(() => {
        if (user.loaded === false) return

        if (user.loggedIn === true) {
            window.location.href = window.location.origin
        }
    }, [user.loaded])

    const sendMail = (e) => {
        e.preventDefault()
        fetch(Settings.Domain + Settings.ResetPasswordMail, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email: email,
            })
        })
            .then(response => {
                return response.json()
            })
            .then(data => {
                if (data.success) {
                    opensnackbar("success", data.response)
                    
                    setDelivered(true)
                }

                if (data.error) {
                    opensnackbar("error", data.error)
                }
            })
    }

    return (
        <div className='form-holder'>
            <form onSubmit={sendMail} className='login'>
                {delived == false ? (
                    <>
                        <h1 className='form-title'>Reset Password</h1>
                        <div className='form-label'>
                            <p>Email</p>
                            <input type='email' placeholder='example@gmail.com' onChange={(e) => setEmail(e.target.value)}></input>
                        </div>
                        <button className='btn primary' onClick={sendMail}>Send</button>
                        <br></br>
                        <span>Not a member? <Link to="/Register">Register</Link></span>
                    </>
                ) : (
                    <h1 className='form-title'>Check your mail: {email}</h1>
                )}
            </form>
            <CustomizedSnackbars messageType={messageType} message={responseMessage} openSnackbar={snackBarOpen} key={key} />
        </div>
    )
}

export default ForgotPassword