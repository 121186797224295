import React, { useEffect, useState } from 'react';
import CustomSlider from './CustomSlider';
import Settings from "../Settings.json"

function Slider() {

  const [items, setItems] = useState([])

  useEffect(() => {
    fetch(Settings.Domain + Settings.GetBanner, {
      method: "GET"
    })
      .then(response => {
        return response.json()
      })
      .then(data => {
        if (data.response) {
          setItems(data.response)
        }
      })
  }, [])

  return (
    <CustomSlider items={items} />
  );
}

export default Slider;
