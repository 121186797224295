import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import Settings from "../../Components/Settings.json"

import "./Verify.css";

function Verify() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");

  const [message, setMessage] = useState("Loading...")

  useState(() => {
    fetch(Settings.Domain + Settings.VerifyEmail, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: token,
        })
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          const response = data

          if (data.success) {
            setMessage("Account verified")
          } else {
            setMessage(response.error)
          }
        })
        .catch((error) => {
          setMessage(error.error)
        });
  }, [])

  return (
    <div className='verify-container'>
      <div className='verify-holder'>
        <h2 style={{ fontSize: 36 }}>Verification</h2>
        <p>{message}</p>
      </div>
    </div>
  );
}

export default Verify;
