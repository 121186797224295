import React, { useEffect, useState } from 'react';
import { useAuth } from '../../Components/AuthProvider/AuthProvider';
import Car from "../../Components/AdminPanel/CarRental/Car";
import User from "../../Components/AdminPanel/UsersPanel/UserPanel";
import Books from "../../Components/AdminPanel/Books/Books";
import Website from '../../Components/AdminPanel/Website/Website';
import Expedition from '../../Components/AdminPanel/Exptedition/Expedition';
import "./AdminPanel.css";
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { TabContext, TabList } from '@mui/lab';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

function AdminPanel() {
    const { user } = useAuth();
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        console.log(newValue);
        setValue(newValue);
    };

    const { type, action, id } = useParams();

    useEffect(() => {
        if (user.loaded === false) return;

        if (user.memberShip !== "Admin") {
            window.location.href = window.location.origin;
            return;
        }
    }, [user.loaded, type, user.memberShip]);

    return (
        <div className='admin-panel-pages'>
            <Box
                sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}
                className="side-adminpanel-navbar"
            >
                <Tabs
                    className='admin-panel-left-pagination'
                    orientation="vertical"
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    sx={{ borderRight: 1, borderColor: 'divider' }}
                >
                    <Tab label="Cars" {...a11yProps(0)} />
                    <Tab label="Users" {...a11yProps(1)} />
                    <Tab label="Books" {...a11yProps(2)} />
                    <Tab label="Website" {...a11yProps(3)} />
                    <Tab label="Expeditions" {...a11yProps(4)} />
                </Tabs>
                <TabPanel value={value} index={0} className="admin-page">
                    <Car />
                </TabPanel>
                <TabPanel value={value} index={1} className="admin-page">
                    <User />
                </TabPanel>
                <TabPanel value={value} index={2} className="admin-page">
                    <Books />
                </TabPanel>
                <TabPanel value={value} index={3} className="admin-page">
                    <Website />
                </TabPanel>
                <TabPanel value={value} index={4} className="admin-page">
                    <Expedition />
                </TabPanel>
            </Box>
            <Box sx={{ width: '100%', bgcolor: 'background.paper', typography: 'body1' }} className="top-adminpanel-navbar">
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example" variant="scrollable" scrollButtons="auto">
                            <Tab label="Cars" value={5} {...a11yProps(5)} />
                            <Tab label="Users" value={6} {...a11yProps(6)} />
                            <Tab label="Books" value={7} {...a11yProps(7)} />
                            <Tab label="Website" value={8} {...a11yProps(8)} />
                            <Tab label="Expeditions" value={9} {...a11yProps(9)} />
                        </TabList>
                    </Box>
                    <TabPanel value={value} index={5} className="admin-page">
                        <Car />
                    </TabPanel>
                    <TabPanel value={value} index={6} className="admin-page">
                        <User />
                    </TabPanel>
                    <TabPanel value={value} index={7} className="admin-page">
                        <Books />
                    </TabPanel>
                    <TabPanel value={value} index={8} className="admin-page">
                        <Website />
                    </TabPanel>
                    <TabPanel value={value} index={9} className="admin-page">
                        <Expedition />
                    </TabPanel>
                </TabContext>
            </Box>
        </div>
    );
}

export default AdminPanel;
