import React, { useEffect, useState } from 'react'

import AddCar from "./AddCar/AddCar"
import EditCar from "./EditCar/EditCar"
import RemoveCar from "./RemoveCar/RemoveCar"

import "./Car.css"
import { useParams } from 'react-router-dom'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TabPanel from "@mui/lab/TabPanel"
import TabContext from '@mui/lab/TabContext';
import CarItems from './CarItems/CarItems'

function Car() {


  const [value, setValue] = useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", bgcolor: 'background.paper', margin: "0 0 0 10px" }}>
      <TabContext value={value}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Add" value={"1"}/>
          <Tab label="Edit" value={"2"}/>
          <Tab label="Remove" value={"3"}/>
          <Tab label="Items" value={"4"}/>
        </Tabs>
        <TabPanel value="1">
          <AddCar></AddCar>
        </TabPanel>
        <TabPanel value="2">
          <EditCar></EditCar>
        </TabPanel>
        <TabPanel value="3">
          <RemoveCar></RemoveCar>
        </TabPanel>
        <TabPanel value="4">
          <CarItems></CarItems>
        </TabPanel>
      </TabContext>
    </Box>
  )
}

export default Car