import React, { useState, useRef } from 'react';
// import './test.css'; // Assuming you have a CSS file for styling

function Test() {
  const [showText, setShowText] = useState(false);
  const [circlePosition, setCirclePosition] = useState({ x: 0, y: 0 });
  const [text, setText] = useState('');
  const [hoveredText, setHoveredText] = useState('');
  const circleRef = useRef(null);
  const textRef = useRef(null);

  const handleMouseMove = (e) => {
    setCirclePosition({ x: e.clientX, y: e.clientY });
    if (circleRef.current) {
      circleRef.current.style.left = e.clientX + 'px';
      circleRef.current.style.top = e.clientY + 'px';
    }
  };

  const handleMouseEnter = (e) => {
    setShowText(true);
    setText(e.target.innerText);
    setHoveredText(e.target.innerText);
  };

  const handleMouseLeave = () => {
    //setShowText(false);
    setText('');
    setHoveredText('');
  };

  const handleHoveredText = (e) => {
    const rect = e.target.getBoundingClientRect();
    const mouseX = e.clientX - rect.left;
    const mouseY = e.clientY - rect.top;

    if (textRef.current) {
      const textWidth = textRef.current.offsetWidth;
      const textHeight = textRef.current.offsetHeight;
      const circleWidth = circleRef.current.offsetWidth;
      const circleHeight = circleRef.current.offsetHeight;

      let left = mouseX - textWidth / 2;
      let top = mouseY - textHeight * 4;

      textRef.current.style.top = left + "px"
      textRef.current.style.right = mouseX * 8 + "px"


      console.log(left);
    }
  };

  return (
    <div className="container" onMouseMove={handleMouseMove}>
      {showText && (
        <div ref={circleRef} className="circle">
          <div className="circle-text" ref={textRef}>
            Hover me
          </div>
        </div>
      )}
      <div
        className="text"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onMouseMove={handleHoveredText}
      >
        Hover over me!
      </div>
    </div>
  );
}

export default Test;
