import React, { useEffect, useState } from 'react'

import { Link, useLocation } from 'react-router-dom'
import Settings from "../../Components/Settings.json"
import { useAuth } from '../../Components/AuthProvider/AuthProvider'

import CustomizedSnackbars from "../../Components/Snackbar/Snackbar"

function ResetPassword() {

  const { user, login } = useAuth()

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");

  const [password, setPassword] = useState("")
  const [verifyPassword, setVerifyPassword] = useState("")

  const [responseMessage, setResponseMessage] = useState()
  const [messageType, setMessageType] = useState()
  const [snackBarOpen, setSnackBarOpen] = useState(false)
  const [key, setKey] = useState(0)

  const [delived, setDelivered] = useState(false)
  const [message, setMessage] = useState("")

  const opensnackbar = (type, message) => {
    setSnackBarOpen(true)
    setMessageType(type)
    setResponseMessage(message)
    setKey(key + 1)
  }

  useEffect(() => {
    if (user.loaded === false) return

    if (user.loggedIn === true) {
      window.location.href = window.location.origin
    }
  }, [user.loaded])

  const checkToken = () => {
    fetch(Settings.Domain + Settings.CheckToken, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        token: token
      })
    })
    .then(response => {
      return response.json()
    })
    .then(data => {
      if (data.error) {
        setMessage(data.error)
        setDelivered(true)
      }
    })
  }

  const resetPassword = (e) => {
    e.preventDefault()
    fetch(Settings.Domain + Settings.ChangePassword, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: token,
        password: password,
        repeatPassword: verifyPassword
      })
    })
      .then(response => {
        return response.json()
      })
      .then(data => {
        if (data.success) {
          opensnackbar("success", data.response)
          window.location.href = window.location.origin + "/login"
        }

        if (data.error) {
          opensnackbar("error", data.error)
        }
      })
      .catch(err => {
        console.log(err);
      })
  }

  useEffect(() => {
    checkToken()
  }, [])

  return (
    <div className='form-holder'>
      <form onSubmit={resetPassword} className='login'>
        {delived == false ? (
          <>
            <h1 className='form-title'>New password</h1>
            <div className='form-label'>
              <p>Password</p>
              <input type='password' onChange={(e) => setPassword(e.target.value)}></input>
            </div>
            <div className='form-label'>
              <p>Repeat Password</p>
              <input type='password' onChange={(e) => setVerifyPassword(e.target.value)}></input>
            </div>
            <button className='btn primary' onClick={resetPassword}>Reset</button>
            <br></br>
            <span>Not a member? <Link to="/Register">Register</Link></span>
          </>
        ) : (
          <h1 className='form-title'>{message}</h1>
        )}
      </form>
      <CustomizedSnackbars messageType={messageType} message={responseMessage} openSnackbar={snackBarOpen} key={key} />
    </div>
  )
}

export default ResetPassword