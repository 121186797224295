import React from 'react'

import WhatsAppIcon from '@mui/icons-material/WhatsApp';

import Settings from "../../Settings.json"

import "./Whatsapp.css"

function Whatsapp() {
  return (
    <a className='whatsapp-icon' href="https://wa.me/995511281212">
        <img src={ window.location.origin + '/WhatsAppButtonGreenSmall.svg'}></img>
    </a>
  )
}

export default Whatsapp