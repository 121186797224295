import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Settings from "../../Settings.json";
import "./Website.css"
import { Dialog, DialogActions, DialogContent, DialogContentText, TextField, Button, Select, MenuItem } from "@mui/material";
import ColorPicker from "react-pick-color";

import UploadFileIcon from '@mui/icons-material/UploadFile';

import CustomizedSnackbars from '../../Snackbar/Snackbar';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useAuth } from "../../AuthProvider/AuthProvider"

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  ...draggableStyle,
  width: "100%",
  position: "relative",
  display: "flex",
});

const getListStyle = (isDraggingOver) => ({
  padding: grid,
  width: "100%",
  height: "fit-content",
});

const getImageStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "lightblue" : "lightgrey",
  width: "100%",
  filter: isDraggingOver ? "contrast(0.3)" : "contrast(1)",
  borderRadius: 16,
  cursor: "pointer"
});


function Website() {

  const { user, setUser } = useAuth()

  const [items, setItems] = useState([]);
  const [error, setError] = useState(null);

  const [selectedModal, setSelectedModal] = useState()

  const [editBannerModalOpen, setEditBannerModalOpen] = useState(false)
  const [addBannerModalOpen, setAddBannerModalOpen] = useState(false)
  const [deleteBannerModalOpen, setDeleteModalOpen] = useState(false)

  const [addBannerTitle, setAddBannerTitle] = useState("")
  const [addBannerDescription, setAddBannerDescription] = useState("")

  const [addBannerImageComputer, setAddBannerImageComputer] = useState()
  const [addBannerImagePhone, setAddBannerImagePhone] = useState()

  const [editBannerTitle, setEditBannerTitle] = useState("")
  const [editBannerDescription, setEditBannerDescription] = useState("")


  const [addTextOne, setTextOne] = useState("")
  const [addTextTwo, setTextTwo] = useState("")
  const [addTextThree, setTextThree] = useState("")

  const [carDiscounts, setCarDiscounts] = useState([])
  const discountDays = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31]

  const [responseMessage, setResponseMessage] = useState()
  const [messageType, setMessageType] = useState()
  const [snackBarOpen, setSnackBarOpen] = useState(false)
  const [key, setKey] = useState(0)


  const [carDiscountMinDay, setCarDiscountMinDay] = useState(0)
  const [carDiscountMaxDay, setCarDiscounMaxDay] = useState(0)
  const [discountAmount, setDiscountAmout] = useState(0)

  const loadCarDiscounts = () => {
    fetch(Settings.Domain + Settings.GetCarDiscounts, {
      method: "GET",
    })
      .then((response) => {
        return response.json();
      })
      .then(data => {
        if (data.success) {
          setCarDiscounts(data.response)
        }
      })
      .catch(err => {
        console.log(err);
      })
  }

  const savedDiscount = () => {
    fetch(Settings.Domain + Settings.SaveCarDiscount, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        min: carDiscountMinDay,
        max: carDiscountMaxDay,
        amount: discountAmount,
        token: user.token
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then(data => {
        if (data.success) {
          setCarDiscounMaxDay(0)
          setCarDiscountMinDay(0)
          setDiscountAmout(0)
          loadCarDiscounts()
          opensnackbar("success", data.response)
        } else {
          opensnackbar("error", data.error)
        }
      })
      .catch(err => {
        console.log(err);
      })
  }

  const updateCarDiscountArray = (arrayId, type, value) => {
    setCarDiscounts(prevCarDiscounts => {
      return prevCarDiscounts.map(discount => {
        if (discount.id === arrayId) {
          if (type === "amount") {
            return { ...discount, amount: value };
          } else if (type === "min_day") {
            return { ...discount, min_day: value };
          } else if (type === "max_day") {
            return { ...discount, max_day: value };
          }
        }
        return discount;
      });
    });
  };


  const updateCarDiscount = (id) => {

    const carDiscount = carDiscounts.find(discount => discount.id === id)

    fetch(Settings.Domain + Settings.UpdateCarDiscount, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: carDiscount.id,
        min: carDiscount.min_day,
        max: carDiscount.max_day,
        amount: carDiscount.amount,
        token: user.token
      }),
    }).then(response => {
      return response.json();
    })
      .then(data => {
        if (data.success) {
          loadCarDiscounts()
          opensnackbar("success", data.response)
        } else {
          opensnackbar("error", data.error)
        }
      })
  }

  const deleteCarDiscount = (id) => {
    fetch(Settings.Domain + Settings.DeleteCarDiscounts, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: id,
        token: user.token
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then(data => {
        if (data.success) {
          loadCarDiscounts()
          opensnackbar("success", data.response)
        } else {
          opensnackbar("error", data.error)
        }
      })
      .catch(err => {
        console.log(err);
      })
  }

  const opensnackbar = (type, message) => {
    setSnackBarOpen(true)
    setMessageType(type)
    setResponseMessage(message)
    setKey(key + 1)
  }

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const updatedItems = reorder(items, result.source.index, result.destination.index);
    setItems(updatedItems);

    const orderedIDs = updatedItems.map((item, index) => {
      return {
        index: index + 1,
        id: item.id
      }
    });

    fetch(Settings.Domain + Settings.UpdateBannerOrder, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        orderedIDs: orderedIDs,
        token: user.token
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((e) => {
        setItems([])
        loadBanners()
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleImageChangeComputer = (e) => {
    const file = e.target.files[0]
    setAddBannerImageComputer(file)
  };

  const handleImageChangePhone = (e) => {
    const file = e.target.files[0]
    setAddBannerImagePhone(file)
  };

  const saveBanner = () => {
    const formData = new FormData();
    formData.append("title", addBannerTitle);
    formData.append("description", addBannerDescription);
    formData.append("image_computer", addBannerImageComputer); // For computer image
    formData.append("image_phone", addBannerImagePhone); // For phone image
    formData.append("token", user.token);    

    fetch(Settings.Domain + Settings.SaveBanner, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.success) {
          if (data.response) {

            data = data.response

            const newBannerItem = {
              id: data.id,
              title: data.title,
              description: data.description,
              imageUrl: Settings.Domain + "/images/" + data.imageUrl,
            };

            setItems([...items, newBannerItem]);
            setAddBannerModalOpen(false);
            setAddBannerTitle("");
            setAddBannerDescription("");
            setAddBannerImageComputer(null);
            setAddBannerImagePhone(null);
          }
          if (data.error) {
            opensnackbar("error", data.error)
          }
        }
      })
      .catch((err) => {
        setError(err.message);
      });
  }

  const loadBanners = () => {
    fetch(Settings.Domain + Settings.GetBanner, {
      method: "GET",
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.success) {

          data.response.forEach(banner => {
            banner.imageUrl = Settings.Domain + "/images/" + banner.imageUrl
          })

          console.log(data.response);

          setItems(data.response)
        }
      })
      .catch((err) => {
        setError(err.message);
      });
  }

  const loadText = () => {
    fetch(Settings.Domain + Settings.GetText, {
      method: "GET"
    })
      .then(response => {
        return response.json()
      })
      .then(data => {
        if (data.success) {
          const text1 = data.response[0].text
          const text2 = data.response[1].text
          const text3 = data.response[2].text

          setTextOne(text1)
          setTextTwo(text2)
          setTextThree(text3)
        }
      })
  }

  const saveText = () => {
    fetch(Settings.Domain + Settings.UpdateText, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        token: user.token,
        text1: addTextOne,
        text2: addTextTwo,
        text3: addTextThree
      }),
    })
      .then(response => {

        return response.json()
      })
      .then(data => {
        if (data.success) {
          opensnackbar("success", data.response)
        } else if (data.error) {
          opensnackbar("success", data.error)
        }
      })
  }

  const saveColor = () => {
    fetch(Settings.Domain + Settings.UpdateColors, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        token: user.token,
        primary: user.primaryColor,
        secondary: user.secondaryColor,
      }),
    })
      .then(respone => {
        return respone.json()
      })
      .then(data => {
        if (data.success) {
          opensnackbar("success", data.response)
        }
      })
      .catch(err => {
        console.log(err);
      })
  }

  const deletBanner = () => {
    fetch(Settings.Domain + Settings.DeleteBanner, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        token: user.token,
        id: selectedModal
      }),
    })
      .then(response => {
        return response.json()
      })
      .then(data => {
        if (data.success) {
          opensnackbar("success", data.response)
          loadBanners()
        }
        setSelectedModal(0)
        setDeleteModalOpen(false)
      })
  }

  const saveEditBanner = () => {
    fetch(Settings.Domain + Settings.UpdateBanner, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        banner_id: selectedModal,
        title: editBannerTitle,
        description: editBannerDescription,
        token: user.token
      }),
    })
      .then(response => {
        return response.json()
      })
      .then(data => {
        if (data.success) {
          opensnackbar("success", data.response)
        }

        if (data.error) {
          opensnackbar("error", data.error)
        }
        loadBanners()
      })
      .catch(error => {
        console.log(error);
      })
  }

  useEffect(() => {
    loadBanners()
    loadText()
    loadCarDiscounts()
  }, []);

  return (
    <div style={{ width: "100%" }} className="website-editor">
      {error && <p>Error: {error}</p>}
      <Accordion className="collapse">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Banner Editor</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    className="item-list-holder"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {items.map((item, index) => (
                      <Draggable key={item.id.toString()} draggableId={item.id.toString()} index={index}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                            onClickCapture={(e) => {
                              setEditBannerDescription(item.description)
                              setEditBannerTitle(item.name)
                              setSelectedModal(item.id)
                              setEditBannerModalOpen(true)
                            }}
                          >
                            <img src={Settings.Domain + "/images/" + item.computerImage} style={getImageStyle(snapshot.isDragging)}></img>
                            <RemoveCircleIcon className="delete-banner-icon" onClick={(e) => {
                              setDeleteModalOpen(true)
                              setSelectedModal(item.id)
                              setEditBannerModalOpen(false)
                            }} style={{ zIndex: 60 }} />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                    <div
                      style={getItemStyle()}
                    >
                      <div className="add-image">
                        <CloudUploadIcon className='image-upload-svg' onClick={() => setAddBannerModalOpen(true)}></CloudUploadIcon>
                      </div>
                    </div>
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className="collapse">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Text Editor</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <div className="container" style={{ display: "flex", flexDirection: "column", width: "100%", margin: "20px 0" }}>
              <TextField
                value={addTextOne}
                variant="outlined"
                multiline
                minRows={4}
                fullWidth
                label="Text 1"
                onChange={(e) => {
                  setTextOne(e.target.value)
                }}
              />
              <br></br>
              <TextField
                value={addTextTwo}
                variant="outlined"
                multiline
                minRows={4}
                fullWidth
                label="Text 2"
                onChange={(e) => {
                  setTextTwo(e.target.value)
                }}
              />
              <br></br>
              <TextField
                value={addTextThree}
                variant="outlined"
                multiline
                minRows={4}
                fullWidth
                label="Text 3"
                onChange={(e) => {
                  setTextThree(e.target.value)
                }}
              />
              <br></br>
              <Button color="primary" variant="outlined" style={{ margin: "0 auto" }} onClick={saveText}>Save Text</Button>
            </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className="collapse">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>Theme Editor</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <div className="container" style={{ display: "flex", flexDirection: "column", width: "100%", margin: "20px 0" }}>
              <div className="color-picker-holder">
                <div className="color-picker">
                  <h2>Primary Color</h2>
                  <ColorPicker color={user.primaryColor} onChange={color => {
                    setUser((prevUser) => ({
                      ...prevUser,
                      primaryColor: color.hex,
                    }));
                  }} />;
                </div>
                <div className="color-picker">
                  <h2>Secondary Color</h2>
                  <ColorPicker color={user.secondaryColor} onChange={color => {
                    setUser((prevUser) => ({
                      ...prevUser,
                      secondaryColor: color.hex,
                    }));
                  }} />;
                </div>
              </div>
              <Button color="primary" variant="outlined" style={{ margin: "0 auto" }} onClick={saveColor}>Save Theme</Button>
            </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className="collapse">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>Car Discounts</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <div className="container" style={{ display: "flex", flexDirection: "column", width: "100%", margin: "20px 0" }}>
              {
                carDiscounts.map((carDiscount, index) => (
                  <div className="car_discount_form">
                    <Select
                      sx={{ width: 100 }}
                      value={carDiscount.min_day}
                      id="outlined-number"
                      type="text"
                      onChange={(e) => {
                        console.log(e.target.value);
                        updateCarDiscountArray(carDiscount.id, "min_day", e.target.value)
                      }}
                    >
                      {
                        discountDays.map((day, index) => (
                          <MenuItem value={day}>{day}</MenuItem>
                        ))
                      }
                    </Select>
                    <br></br>
                    <Select
                      value={carDiscount.max_day}
                      sx={{ width: 100 }}
                      id="outlined-number"
                      type="text"
                      onChange={(e) => {
                        console.log(e.target.value);
                        updateCarDiscountArray(carDiscount.id, "max_day", e.target.value)
                      }}
                    >
                      {discountDays.map((day, index) => (
                        <MenuItem key={index} value={day <= 30 ? day : ">"}>{day <= 30 ? day : ">"}</MenuItem>
                      ))}
                    </Select>

                    <br></br>
                    <Select
                      value={carDiscount.amount}
                      sx={{ width: 100 }}
                      id="outlined-number"
                      type="text"
                      onChange={(e) => {
                        console.log(e.target.value);
                        updateCarDiscountArray(carDiscount.id, "amount", e.target.value)
                      }}
                    >
                      {
                        Array.from({ length: 100 }, (_, index) => index + 1).map((day, index) => (
                          <MenuItem value={day}>{day}%</MenuItem>
                        ))
                      }
                    </Select>
                    <br></br>
                    <Button variant="outlined" sx={{ height: 55, width: 100 }} onClick={(e) => { updateCarDiscount(carDiscount.id) }}>Save</Button>
                    <br></br>
                    <Button variant="outlined" color="error" sx={{ height: 55, width: 100 }} onClick={(e) => {
                      deleteCarDiscount(carDiscount.id)
                    }}>Remove</Button>
                  </div>
                ))
              }
              <div className="car_discount_form">
                <Select
                  sx={{ width: 100 }}
                  value={carDiscountMinDay}
                  id="outlined-number"
                  type="text"
                  onChange={(e) => {
                    setCarDiscountMinDay(e.target.value)
                  }}
                >
                  {
                    discountDays.map((day, index) => (
                      <MenuItem value={day}>{day}</MenuItem>
                    ))
                  }
                </Select>
                <br></br>
                <Select
                  value={carDiscountMaxDay}
                  sx={{ width: 100 }}
                  id="outlined-number"
                  type="text"
                  onChange={(e) => {
                    setCarDiscounMaxDay(e.target.value)
                  }}
                >
                  {
                    discountDays.map((day, index) => (
                      day <= 30 ? (
                        <MenuItem key={index} value={day}>{day}</MenuItem>
                      ) : (
                        <MenuItem key={index} value={day}>{">"}</MenuItem>
                      )
                    ))
                  }
                </Select>
                <br></br>
                <Select
                  value={discountAmount}
                  sx={{ width: 100 }}
                  id="outlined-number"
                  type="text"
                  onChange={(e) => {
                    setDiscountAmout(e.target.value)
                  }}
                >
                  {
                    Array.from({ length: 100 }, (_, index) => index + 1).map((day, index) => (
                      <MenuItem value={day}>{day}%</MenuItem>
                    ))
                  }
                </Select>
                <br></br>
                <Button variant="outlined" sx={{ height: 55, width: 100 }} onClick={savedDiscount}>Add</Button>

              </div>
            </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Dialog
        fullWidth={"lg"}
        open={addBannerModalOpen}
      >
        <DialogContent style={{ display: "flex", flexDirection: "column" }}>
          <DialogContentText>
            Add Banner
          </DialogContentText>
          <div className="imageUploader">
            <p>Computer</p>
            {addBannerImageComputer ? (
              <img src={URL.createObjectURL(addBannerImageComputer)} alt="Uploaded" />
            ) : (
              <UploadFileIcon className="upload-icon" />
            )}
            <input type="file" onChange={handleImageChangeComputer} />
          </div>
          <br></br>
          <div className="imageUploader">
          <p>Phone</p>
            {addBannerImagePhone ? (
              <img src={URL.createObjectURL(addBannerImagePhone)} alt="Uploaded" />
            ) : (
              <UploadFileIcon className="upload-icon" />
            )}
            <input type="file" onChange={handleImageChangePhone} />
          </div>
          <br></br>
          <TextField
            value={addBannerTitle}
            label="Title"
            variant="standard"
            onChange={(e) => {
              setAddBannerTitle(e.target.value)
            }}
          />
          <br></br>
          <br></br>
          <TextField
            value={addBannerDescription}
            label="Description"
            variant="standard"
            multiline
            minRows={4}
            onChange={(e) => {
              setAddBannerDescription(e.target.value)
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddBannerModalOpen(false)} color="error">
            Close
          </Button>
          <Button onClick={saveBanner}>Save</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth={"sm"}
        open={deleteBannerModalOpen}
      >
        <DialogContent style={{ display: "flex", flexDirection: "column" }}>
          <DialogContentText>
            Delete Banner
          </DialogContentText>
          <DialogContent>
            Are you sure you want to delete this banner?
          </DialogContent>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            setDeleteModalOpen(false)
            setSelectedModal()
          }} color="info">
            Close
          </Button>
          <Button onClick={deletBanner} color="error">Delete</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth={"sm"}
        open={editBannerModalOpen}
      >
        <DialogContent style={{ display: "flex", flexDirection: "column" }}>
          <DialogContentText>
            Edit Banner
          </DialogContentText>
          <DialogContent>
            <TextField
              fullWidth
              value={editBannerTitle}
              label="Title"
              variant="standard"
              onChange={(e) => {
                setEditBannerTitle(e.target.value)
              }}
            />
            <br></br>
            <br></br>
            <TextField
              fullWidth
              value={editBannerDescription}
              label="Description"
              variant="standard"
              multiline
              minRows={4}
              onChange={(e) => {
                setEditBannerDescription(e.target.value)
              }}
            />
          </DialogContent>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            setEditBannerModalOpen(false)
            setSelectedModal()
          }} color="secondary">
            Close
          </Button>
          <Button onClick={saveEditBanner} color="primary">Save</Button>
        </DialogActions>
      </Dialog>
      <CustomizedSnackbars messageType={messageType} message={responseMessage} openSnackbar={snackBarOpen} key={key} />
    </div>
  );
}

export default Website;
