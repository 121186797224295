import React, { useEffect, useState } from 'react'

import "./Tours.css"
import { Link } from 'react-router-dom'
import Settings from "../../Components/Settings.json"
import { Helmet } from 'react-helmet-async'

function Tours() {

  const [tourList, setTourList] = useState([])

  useEffect(() => {
    fetch(Settings.Domain + Settings.GetExpeditions, {
      method: "GET",
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setTourList(data.response)
        } else if (data.error) {
          //opensnackbar("error", data.error)
        } else {
        }
      })
      .catch(error => {
        console.log(error);
      });
  }, [])

  return (
    <div className='container' style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", margin: "200px 0" }}>
      <Helmet>
        <title>Let's Ride Expedition</title>
        <meta name="keywords" content="adventure, expedition, tours, travel, explore, destinations, nature, adventure trips"></meta>
      </Helmet>
      {tourList.map((tour, index) => (
        <Link key={index} className='tour-element' to={"/tourList/" + tour.id}>
          <img style={{ backgroundImage: `url(${Settings.Domain + "/images/" + tour.banner})`, backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "cover" }}></img>
          <div className='shadow'></div>
          <p className='tour-title'>{tour.title}</p>
        </Link>
      ))}
    </div>

  )


}
export default Tours