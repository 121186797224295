import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
    DateTimePicker,
    LocalizationProvider,
    MobileDateTimePicker,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import { addDays, isWeekend } from 'date-fns'; // Import date-fns functions for date manipulation
import dayjs from 'dayjs';

import './CarData.css';
import { Button, Dialog, DialogActions, FormControl, DialogContent, DialogContentText, DialogTitle, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, TextField } from '@mui/material';

import MuiPhoneNumber from 'material-ui-phone-number';

import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LuggageIcon from '@mui/icons-material/Luggage';
import WhatshotIcon from "@mui/icons-material/Whatshot"

import Settings from "../../Components/Settings.json"

import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import TimeToLeaveIcon from '@mui/icons-material/TimeToLeave';
import ShutterSpeedIcon from '@mui/icons-material/ShutterSpeed';
import MinorCrashIcon from '@mui/icons-material/MinorCrash';
import ChairIcon from '@mui/icons-material/Chair';

import { useAuth } from '../../Components/AuthProvider/AuthProvider';
import CustomizedSnackbars from '../../Components/Snackbar/Snackbar';
import { Edit } from '@mui/icons-material';
import { Helmet } from "react-helmet-async"
import MUIRichTextEditor from 'mui-rte';


function CarData() {
    const { carId } = useParams();

    const { user } = useAuth()

    const [responseMessage, setResponseMessage] = useState()
    const [messageType, setMessageType] = useState()
    const [snackBarOpen, setSnackBarOpen] = useState(false)
    const [key, setKey] = useState(0)

    const [rentModelOpen, setRentModalOpen] = useState(false)
    const [rentItemModalOpen, setRentItemModalOpen] = useState(false)

    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [message, setMessage] = useState("")
    const [phoneNumber, setPhonenNmber] = useState("")

    const [selectedImage, setSelectedImage] = useState('');
    const [activeImage, setActiveImage] = useState(null);
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [inTotal, setInTotal] = useState(0)
    const [serviceFee, setServiceFee] = useState(0)
    const [itemPrice, setItemPrice] = useState(0)

    const [pickUp, setPickUp] = useState(1);
    const [dropOff, setDropOff] = useState(1);

    const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(false);

    const [bookPosition, setBookPosition] = useState(0)

    const [importantMessage, setImportantMessage] = useState("")

    const [places, setPlaces] = useState([])

    const [orderDates, setOrderDates] = useState([])

    const [car, setCar] = useState(null);

    const [itemList, setItemList] = useState(null)
    const [itemListSelected, setItemListSelected] = useState([])
    const [itemListSelectedToshow, setItemListSelectedToshow] = useState([])

    const [carDiscounts, setCarDiscounts] = useState([])
    const [carFeulList, setCarFeulList] = useState([])

    const [discountAmount, setDiscountAmount] = useState(0)

    const [disccountApplied, setDisccountApplied] = useState(false)

    const loadCarDiscounts = () => {
        fetch(Settings.Domain + Settings.GetCarDiscounts, {
            method: "GET",
        })
            .then((response) => {
                return response.json();
            })
            .then(data => {
                if (data.success) {
                    setCarDiscounts(data.response)
                }
            })
            .catch(err => {
                console.log(err);
            })
    }


    const getFeulNameById = (id) => {
        for (let i = 0; i < carFeulList.length; i++) {
            if (carFeulList[i].id == id) {
                return carFeulList[i].fuel_name
            }
        }
    }

    const opensnackbar = (type, message) => {
        setSnackBarOpen(true)
        setMessageType(type)
        setResponseMessage(message)
        setKey(key + 1)
    }


    const checkCarAvalibility = () => {
        if (dayjs(endDate).isBefore(dayjs(startDate))) {
            setInTotal(0)
            setImportantMessage('End date must be greater than or equal to start date');
        } else if (endDate && startDate) {
            const start = dayjs(startDate);
            const end = dayjs(endDate);
            var daysDifference = end.diff(start, 'day');

            if (daysDifference == 0) {
                daysDifference = 1
            }

            setDisccountApplied(false)

            for (var i = 0; i < carDiscounts.length; i++) {
                const selectedDiscount = carDiscounts[i]

                if (daysDifference >= selectedDiscount.min_day  && daysDifference <= selectedDiscount.max_day) {
                    setDiscountAmount((car.price * daysDifference) - ((car.price * daysDifference) * (selectedDiscount.amount/100)))
                    setDisccountApplied(true)
                    break
                } else if (selectedDiscount.max_day === ">") {
                    setDiscountAmount((car.price * daysDifference) - ((car.price * daysDifference) * (selectedDiscount.amount/100)))
                    setDisccountApplied(true)
                    break
                }
            }

            setInTotal(car.price * daysDifference);
        }
    }

    const addStartDate = (date) => {
        setStartDate(date)
    }

    const addEndDate = (date) => {
        setEndDate(date)
    }

    const addActiveImage = (newImage) => {
        if (activeImage) {
            activeImage.classList.remove("active")
        }

        newImage.classList.add("active")
        setActiveImage(newImage)
    }


    const followBookElementToScreen = (e) => {
        setBookPosition(window.scrollY)
    };

    useEffect(() => {
        fetch(Settings.Domain + Settings.LoadFeuls, {
            method: 'GET',
        })
            .then(res => { return res.json() })
            .then(data => {
                if (data.success) {
                    setCarFeulList(data.response)
                }
            })
        loadCarDiscounts()
        loadItems()
        fetch(Settings.Domain + Settings.GetCarOrders, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                car_id: carId,
            })
        })
            .then(response => {
                return response.json()
            })
            .then(data => {
                if (data.response) {
                    const newArray = data.response.map((data) => {
                        return {
                            id: data.order_id,
                            car_id: data.car_id,
                            orderStart: data.start_date,
                            endOrder: data.end_date
                        }
                    })
                    setOrderDates(newArray)
                }
            })

        fetch(Settings.Domain + Settings.GetPlaces, {
            method: "GET"
        })
            .then(response => {

                if (!response.ok) {
                    new Error("Response was not ok")
                    return
                }

                return response.json()
            })
            .then(data => {
                if (data.success) {
                    setPlaces(data.response)
                }
            })

        fetch(Settings.Domain + Settings.GetCar, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                car_id: carId
            })
        })
            .then(response => {
                return response.json()
            })
            .then(data => {
                const car = data.response.car[0]
                const carData = data.response.car_data[0]

                const images = JSON.parse(car.car_images)

                const newimageList = images.map((image) => {
                    return Settings.Domain + "/images/" + image
                })

                const carPlaces = JSON.parse(carData.car_places)
                const carRelease = JSON.parse(carData.car_release)

                var keywords = carData.keywords || ""

                setCar({
                    id: car.car_data_id,
                    images: newimageList,
                    price: car.car_price,
                    passanger: 4,
                    service_fee: 40,
                    description: car.car_description,
                    name: car.car_name,
                    car_data: {
                        Keywords: JSON.parse(keywords).join(","),
                        Fuel: carData.car_fuel,
                        Seat: carData.car_seats,
                        Release: carRelease.join("-"),
                        Burn: carData.burn,
                        Luggage: carData.car_luggage,
                        Characteristics: carData.car_characteristics,
                        Engine: carData.car_engine,
                        Type: carData.car_automatic
                    },
                    car_places: carPlaces,
                })

                setSelectedImage(newimageList[0])
            })
            .catch(error => {
                console.log(error);
            })


        window.addEventListener('scroll', followBookElementToScreen);
    }, []);

    useEffect(() => {

        checkCarAvalibility()

        if (!car) {
            return
        }

        const placeFound = car.car_places.find((data, index) => data.id == pickUp)

        if (placeFound.amount === 0) {
            setServiceFee(car.service_fee * 2)
            setImportantMessage("Because there are no cars at your selected pickup location, we need to manually transport them there, so there will be a service fee for this.")
            return
        }

        if (pickUp != dropOff) {
            setServiceFee(car.service_fee * 2)
            setImportantMessage("Since the drop-off location is different from the pickup location, there will be a service fee applied.")
            return
        }


        setImportantMessage("")
        setServiceFee(0)
    }, [pickUp, dropOff, startDate, endDate])

    useEffect(() => {

        if (itemList) {
            const newArray = itemListSelected.reduce((accumulator, item) => {
                const existingItem = accumulator.find((existing) => existing.id === item.id);

                if (!existingItem) {
                    const itemAmount = itemListSelected.filter(selectedId => selectedId.id === item.id).length;
                    const itemFound = itemList.find((listItem) => listItem.id === item.id);

                    if (itemFound) {
                        accumulator.push({
                            id: item.id,
                            item_name: itemFound.item_name,
                            amount: itemAmount,
                            price: itemFound.item_price
                        });
                    }
                }

                return accumulator;
            }, []);

            var price = 0

            newArray.map((item, index) => {
                price = price + item.amount * item.price
            })

            setItemPrice(price)

            setItemListSelectedToshow(newArray);
        }
    }, [itemListSelected, itemList]);

    const disableDays = (date) => {
        const carFound = car.car_places.some((data, index) => data.amount > 0)

        const isDisabled = orderDates.some((order) => {

            if (carFound) {
                return false
            }

            const orderStartDate = dayjs(order.orderStart, 'MM-DD-YY');
            const orderEndDate = dayjs(order.endOrder, 'MM-DD-YY');
            return date >= orderStartDate && date <= orderEndDate;
        });

        return date < dayjs() || isDisabled;
    };

    const RentCar = () => {

        var items = []

        itemListSelectedToshow.map((item, index) => {
            items.push({
                id: item.id,
                amount: item.amount
            })
        })

        fetch(Settings.Domain + Settings.BookCar, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                firstName: firstName,
                lastName: lastName,
                email: email,
                phoneNumber: phoneNumber,
                message: message,
                startDate: startDate,
                endDate: endDate,
                pickUp: pickUp,
                dropOff: dropOff,
                token: user.token,
                car_id: carId,
                items: items
            })
        })
            .then(response => {
                return response.json()
            })
            .then(data => {
                if (data.error) {
                    opensnackbar("error", data.error)
                    return
                }

                opensnackbar("success", data.response)
            })
            .catch(err => {
                opensnackbar("error", err)
            })
    }

    const loadItems = () => {
        fetch(Settings.Domain + Settings.LoadCarItem, {
            method: "GET",
        })
            .then(response => {
                return response.json()
            })
            .then(data => {
                if (data.success) {
                    setItemList(data.response)
                } else {
                    setItemList([]);
                }
            })
            .catch(error => {
                console.log(error);
            })
    }

    return (
        <div className="container" style={{ margin: "200px 0" }}>
            {car && (
                <Helmet>
                    <title>{car.name}</title>
                    <meta name="keywords"
                        content={car.car_data.Keywords}></meta>
                </Helmet>
            )}
            {car && (
                <div className="car-info-holder">
                    <div className="car-data">
                        <div className='car-images'>
                            <img className="main-image" src={selectedImage} alt="Car"></img>
                            <div className="image-list">
                                {car.images.map((image, index) => (
                                    <img
                                        key={index}
                                        src={image}
                                        onClick={(e) => {
                                            addActiveImage(e.currentTarget)
                                            setSelectedImage(image)
                                        }}
                                        alt={`Car ${index}`}
                                    ></img>
                                ))}
                            </div>
                            {user.memberShip === "Admin" && (
                                <Link className='edit-link' to={"/adminPanel/car/edit/" + carId}>
                                    <Edit className='edit-icon'></Edit>
                                </Link>
                            )}
                        </div>
                        <pre className='car-description'>
                            <div className='car-information-title'>
                                <p>Description</p>
                            </div>
                            <MUIRichTextEditor
                                id='description'
                                value={car.description}
                                readOnly
                                toolbar={false}
                                
                            ></MUIRichTextEditor>
                        </pre>
                        <div className='car-data-list' style={{ display: "flex", flexDirection: "column" }}>
                            <div className='car-information-title'>
                                <p>Informartion</p>
                            </div>
                            <div className='car-data-set'>
                                <div className='car-detail-data'>
                                    <div className='icon'>
                                        <LocalGasStationIcon></LocalGasStationIcon>
                                        <span>Fuel</span>
                                    </div>
                                    <span>{getFeulNameById(car.car_data.Fuel)}</span>
                                </div>
                                <div className='car-detail-data'>
                                    <div className='icon'>
                                        <ChairIcon></ChairIcon>
                                        <span>Seats</span>
                                    </div>
                                    <span>{car.car_data.Seat}</span>
                                </div>
                                <div className='car-detail-data'>
                                    <div className='icon'>
                                        <CalendarMonthIcon></CalendarMonthIcon>
                                        <span>Release</span>
                                    </div>
                                    <span>{car.car_data.Release}</span>
                                </div>
                                <div className='car-detail-data'>
                                    <div className='icon'>
                                        <WhatshotIcon></WhatshotIcon>
                                        <span>Burn</span>
                                    </div>
                                    <span>{car.car_data.Burn} L</span>
                                </div>
                                <div className='car-detail-data'>
                                    <div className='icon'>
                                        <TimeToLeaveIcon></TimeToLeaveIcon>
                                        <span>Type</span>
                                    </div>
                                    <span>{car.car_data.Type == 1 ? "Automatic" : "Mechanical"}</span>
                                </div>
                                <div className='car-detail-data'>
                                    <div className='icon'>
                                        <LuggageIcon></LuggageIcon>
                                        <span>Luggage</span>
                                    </div>
                                    <span>{car.car_data.Luggage} L</span>
                                </div>
                                <div className='car-detail-data'>
                                    <div className='icon'>
                                        <ShutterSpeedIcon></ShutterSpeedIcon>
                                        <span>Engine</span>
                                    </div>
                                    <span>{car.car_data.Engine}</span>
                                </div>
                                <div className='car-detail-data'>
                                    <div className='icon'>
                                        <MinorCrashIcon></MinorCrashIcon>
                                        <span>Characteristics</span>
                                    </div>
                                    <span>{car.car_data.Characteristics}</span>
                                </div>
                            </div>
                        </div>
                        <br></br>
                        <div className='car-discount'>
                            <div className='car-information-title'>
                                <p>Discounts</p>
                            </div>
                            <div className='discount-header'>
                                <div className='discount-title'>
                                    <p>{"Min - Max (Days)"}</p>
                                </div>
                                <div className='discount-price'>
                                    <p>Percentage</p>
                                </div>
                            </div>
                            <div className='car-discount-list'>
                                {carDiscounts.map((carDiscount, index) =>
                                    <div key={index} className='car-discount-item'>
                                        <div className='discount-title'>
                                            <p>{carDiscount.min_day} - {carDiscount.max_day}</p>
                                        </div>
                                        <div className='discount-price'>
                                            <p>{carDiscount.amount}%</p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="car-book" style={{ top: (bookPosition / 350) + 200 }}>
                        <div className='car-information-title'>
                            <p>Informartion</p>
                        </div>
                        <div className='car-book-data'>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <label>Start Date</label>
                                <MobileDateTimePicker
                                    viewRenderers={{
                                        hours: renderTimeViewClock,
                                        minutes: renderTimeViewClock,
                                        seconds: renderTimeViewClock,
                                    }}
                                    disablePast={true}
                                    onChange={addStartDate}
                                    shouldDisableDate={disableDays}
                                    onError={(e) => { console.log(e) }}
                                />
                                <br></br>
                                <br></br>
                                <label>End Date</label>
                                <MobileDateTimePicker
                                    viewRenderers={{
                                        hours: renderTimeViewClock,
                                        minutes: renderTimeViewClock,
                                        seconds: renderTimeViewClock,
                                    }}
                                    disablePast={true}
                                    shouldDisableDate={disableDays}
                                    onChange={addEndDate}
                                    onError={(e) => { console.log(e) }}
                                />
                            </LocalizationProvider>
                            <br></br>
                            <br></br>
                            <label>Pick Up</label>
                            <Select
                                open={open}
                                onClose={() => { setOpen(false) }}
                                onOpen={() => { setOpen(true) }}
                                value={pickUp}
                                label="Pick Up"
                                onChange={(e) => { setPickUp(+e.target.value) }}
                            >
                                {places.map((place, index) => (
                                    <MenuItem value={place.id} key={index}>
                                        {place.place_name}
                                    </MenuItem>
                                ))}
                            </Select>
                            <br></br>
                            <br></br>
                            <label>Drop Off</label>
                            <Select
                                open={open1}
                                onClose={() => { setOpen1(false) }}
                                onOpen={() => { setOpen1(true) }}
                                value={dropOff}
                                label="Pick Up"
                                onChange={(e) => { setDropOff(+e.target.value) }}
                            >
                                {places.map((place, index) => (
                                    <MenuItem value={place.id} key={index}>
                                        {place.place_name}
                                    </MenuItem>
                                ))}
                            </Select>
                            <br></br>
                            <br></br>
                            <label>Items</label>
                            <Button color="inherit" variant="outlined" style={{ height: "60px" }} onClick={(e) => {
                                setRentItemModalOpen(true)
                            }}>Items</Button>
                            <div className='checkout-information'>
                                <br></br>
                                {importantMessage ? (
                                    <span className='important-message'>{importantMessage}</span>
                                ) : (
                                    <div></div>
                                )}
                                <br></br>
                                <div className='checkout-detail'>
                                    <p className='detail-name'>PRICE PER DAY: </p>
                                    <p className='detail-value'>{car.price + user.currency}</p>
                                </div>
                                <div className='checkout-detail'>
                                    <p className='detail-name'>RENTAL PRICE: </p>
                                    <p className='detail-value'>{inTotal + user.currency}</p>
                                </div>
                                <div className='checkout-detail'>
                                    <p className='detail-name'>RENTAL DAYS: </p>
                                    <p className='detail-value'>{inTotal / car.price}</p>
                                </div>
                                <div className='checkout-detail' style={{ display: "flex", flexDirection: "column" }}>
                                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                        <p className='detail-name'>ITEMS: </p>
                                        <p className='detail-value'>{itemPrice + user.currency}</p>
                                    </div>
                                    {itemListSelectedToshow.map((item, index) => (
                                        <div key={index} style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: "0 0 0 20px" }}>
                                            <p style={{ flex: 1 }} className='detail-name'>{item.item_name}</p>
                                            <p style={{ flex: 1, textAlign: "center" }} className='detail-value'>{item.price + " * " + item.amount}</p>
                                            <p style={{ flex: 1, textAlign: "end" }} className='detail-value'>{item.price * item.amount + user.currency}</p>
                                        </div>
                                    ))}
                                </div>
                                <div className='checkout-detail'>
                                    <p className='detail-name'>SERVICE: </p>
                                    <p className='detail-value'>{serviceFee + user.currency}</p>
                                </div>

                                <div className='checkout-total'>
                                    <p className='detail-name'>TOTAL PRICE: </p>
                                    {disccountApplied ? (
                                        <p className='detail-value'>{discountAmount + serviceFee + itemPrice + user.currency}</p>
                                    ) : (
                                        <p className='detail-value'>{inTotal + serviceFee + itemPrice + user.currency}</p>
                                    )}
                                </div>
                                <Button onClick={(e) => {
                                    setRentModalOpen(true)
                                }}
                                    variant="outlined"
                                    sx={{ height: 60 }}
                                >
                                    Book
                                </Button>
                            </div>
                        </div>
                    </div>

                </div>
            )}
            <Dialog open={rentModelOpen} onClose={(e) => {
                setRentModalOpen(false)
            }}>
                <DialogTitle>Rent</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please fill the necessary information to rent a car
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="normal"
                        label="Firstname"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={firstName}
                        onChange={(e) => {
                            setFirstName(e.target.value)
                        }}
                    />
                    <br></br>
                    <TextField
                        autoFocus
                        margin="normal"
                        label="Lastname"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={lastName}
                        onChange={(e) => {
                            setLastName(e.target.value)
                        }}
                    />
                    <br></br>
                    <MuiPhoneNumber
                        defaultCountry={'ge'}
                        onChange={(e) => {
                            setPhonenNmber(e)
                        }}
                        margin="normal"
                        fullWidth
                        variant="standard"
                    />
                    <TextField
                        autoFocus
                        margin="normal"
                        label="Email"
                        type="email"
                        fullWidth
                        variant="standard"
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value)
                        }}
                    />
                    <br></br>
                    <TextField
                        autoFocus
                        margin="normal"
                        label="Message"
                        type="text"
                        fullWidth
                        variant="standard"
                        multiline
                        value={message}
                        onChange={(e) => {
                            setMessage(e.target.value)
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={(e) => {
                        setRentModalOpen(false)
                    }}>Cancel</Button>
                    <Button onClick={RentCar}>Book</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={rentItemModalOpen} onClose={(e) => {
                setRentItemModalOpen(false)
            }}
            >
                <DialogTitle>Rent Item</DialogTitle>
                <DialogContent>
                    {!itemList ? (
                        <DialogContentText>
                            Sorry, there are no items available at the moment.
                        </DialogContentText>
                    ) : (
                        itemList.map((item, index) => (
                            <FormControl sx={{ m: 1, paddingRight: 0 }} variant="outlined" fullWidth key={index}>
                                <InputLabel>{item.item_name}</InputLabel>
                                <OutlinedInput
                                    fullWidth
                                    disabled
                                    style={{ paddingRight: "0", border: "none" }}
                                    type={"text"}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <InputLabel sx={{ width: "100px" }}>{item.item_price + user.currency}</InputLabel>
                                            <OutlinedInput
                                                style={{ paddingRight: "0", border: "none" }}
                                                type='tel'
                                                disabled
                                                value={itemListSelected.filter(selectedId => selectedId.id === item.id).length} // Calculate selected amount based on filtered IDs
                                                endAdornment={
                                                    <InputAdornment position="end" style={{ display: "flex", flexDirection: "column", height: "100%", maxHeight: "fit-content" }}>
                                                        <Button
                                                            variant="text"
                                                            style={{ padding: 0, margin: 0, height: "50%" }}
                                                            onClick={() => {
                                                                const newSelectedItems = [...itemListSelected, { id: item.id }]; // Add item ID to the list
                                                                setItemListSelected(newSelectedItems);
                                                            }}
                                                        >
                                                            <ArrowDropUpIcon />
                                                        </Button>
                                                        <Button
                                                            variant="text"
                                                            style={{ padding: 0, margin: 0, height: "50%" }}
                                                            onClick={() => {
                                                                const indexToRemove = itemListSelected.findIndex(selectedItem => selectedItem.id === item.id);
                                                                if (indexToRemove !== -1) {
                                                                    const newSelectedItems = [...itemListSelected];
                                                                    newSelectedItems.splice(indexToRemove, 1); // Remove item ID from the list
                                                                    setItemListSelected(newSelectedItems);
                                                                }
                                                            }}
                                                        >
                                                            <ArrowDropDownIcon />
                                                        </Button>
                                                    </InputAdornment>
                                                }
                                            />
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        ))

                    )}

                </DialogContent>
                <DialogActions>
                    <Button onClick={(e) => {
                        setRentItemModalOpen(false)
                    }}>Close</Button>
                </DialogActions>
            </Dialog>
            <CustomizedSnackbars messageType={messageType} message={responseMessage} openSnackbar={snackBarOpen} key={key} />
        </div >
    );
}

export default CarData;
