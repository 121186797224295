import { Fragment, React, useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import { ListItemButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import AdbIcon from '@mui/icons-material/Adb';
import "./Navbar.css"
import { List, ListItem, ListItemText } from '@mui/material';
import { Link } from 'react-router-dom';
import { useAuth } from '../AuthProvider/AuthProvider';


function ResponsiveAppBar() {

    const { user, login, logout } = useAuth()

    const pages = [
        { display: 'Home', url: "./" },
        { display: 'Car Rent', url: "./carlist" },
        { display: 'Expeditions', url: "./tours" },
        { display: 'Apartments', url: "./tours" }
    ]
    
    const [settings, setSettings] = useState([
        {display: 'Settings', url: "./settings", func: null},
        {display: 'Orders', url: "./orders", func: null},
        {display: 'Loggout', url: "/", func: null},
    ]);
    

    const [anchorElNav, setAnchorElNav] = useState(null);
    const [anchorElUser, setAnchorElUser] = useState(null);

    const [state, setState] = useState({
        left: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };


    const logoutFunc = () => {
        logout()
    }
 
    useEffect(() => {
        if (user.memberShip === "Admin") {
            setSettings([
                {display: 'Settings', url: "./settings", func: null},
                {display: 'Orders', url: "./orders", func: null},
                {display: 'AdminPanel', url: "./AdminPanel", func: null},
                {display: 'Loggout', url: "/", func: () => {logoutFunc()}},
            ])
        } else {
            setSettings([
                {display: 'Settings', url: "./settings", func: null},
                {display: 'Orders', url: "./orders", func: null},
                {display: 'Loggout', url: "/", func: () => {logoutFunc()}},
            ])
        }
    }, [user.loaded])

    const list = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
            display={"flex"}
            justifyContent={"center"}
            role="presentation"
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List className='navbar-items-left'>
                {pages.map((page) => (
                    <Link to={page.url} key={page.display} style={{ textDecoration: 'none', color: 'inherit' }} className='navbar-item-left'>
                        <ListItemButton className='navbar-item-left'>
                            <ListItemText primary={page.display} onClick={toggleDrawer("left", false)} />
                        </ListItemButton>
                    </Link>
                ))}
            </List>
        </Box>
    );

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (
        <AppBar position="static">
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href="#app-bar-with-responsive-menu"
                        sx={{
                            mr: 2,
                            display: { xs: 'none', md: 'flex' },
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                    </Typography>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <Fragment>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={toggleDrawer("left", true)}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                            <Drawer
                                anchor={"left"}
                                open={state.left}
                                onClose={toggleDrawer("left", false)}  // Change here
                            >
                                {list("left")}
                            </Drawer>

                        </Fragment>
                    </Box>
                    <Link to={"./"}>
                        <img src='/favicon.png' sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} style={{ width: 64, height: 64, borderRadius: 64, padding: 5, margin: 5 }} ></img>
                    </Link>
                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href="#app-bar-with-responsive-menu"
                        sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'none' },
                            flexGrow: 1,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} className='navbar-pages-list'>
                        {pages.map((page) => (
                            <Link
                                className='navbar-item-left top'
                                key={page.display}
                                onClick={handleCloseNavMenu}
                                sx={{ my: 2, color: 'white', display: 'block' }}
                                to={page.url}
                            >
                                {page.display}
                            </Link>
                        ))}
                    </Box>

                    {user.loggedIn === true ? (
                        <Box sx={{ flexGrow: 0 }}>
                            <Tooltip title="Open settings">
                                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                    <Avatar alt={user.firstName} src="/static/images/avatar/2.jpg" />
                                </IconButton>
                            </Tooltip>
                            <Menu
                                sx={{ mt: '45px' }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                            >
                                {settings.map((setting) => (
                                    <MenuItem key={setting.display} onClick={() => {
                                        handleCloseUserMenu()

                                        if (setting.func) {
                                            setting.func()
                                        }
                                    }} >
                                        <Link to={setting.url} style={{textDecoration: "none", color: "black", textAlign: "center"}}>{setting.display}</Link>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                    ) : (
                        <Box sx={{ flexGrow: 0 }}>
                            <Link to={"./Login"} style={{ textDecoration: 'none', color: 'inherit' }} className='navbar-item-left top'>
                                Login
                            </Link>
                        </Box>
                    )}
                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default ResponsiveAppBar;