import React, { useEffect, useState } from 'react';
import './CustomSlider.css';

import Settings from "../Settings.json"
import { useLocation } from 'react-router-dom';

const CustomSlider = ({ items }) => {
    const [activeIndex, setActiveIndex] = useState(0);

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const titleSize = searchParams.get('titleSize');
    const subtitleSize = searchParams.get('subtitleSize');

    const nextSlide = () => {

        if (activeIndex + 1 >= items.length) {
            setActiveIndex(0);
        } else {
            setActiveIndex(activeIndex + 1);
        }
    }

    useEffect(() => {
        const interval = setInterval(() => {
            nextSlide();
        }, 5000);

        return () => {
            clearInterval(interval); // Clear the interval when the component unmounts
        };
    }, [activeIndex, items.length]);

    return (
        <div className="custom-slider">
            <div className="slider-wrapper">
                {items.map((item, index) => (
                    <>
                                            <div
                        key={index}
                        style={{background: `url(${Settings.Domain + "/images/" + item.computerImage})`}}
                        className={`slide computer ${index === activeIndex ? 'active' : ''}`}
                        alt={item.name}
                    >
                        {titleSize != null ? (
                            <h3 style={{ fontSize: `${titleSize}px` }}>{item.name}</h3>
                        ) : (
                            <h3>{item.name}</h3>
                        )}

                        {subtitleSize != null ? (
                            <h3 style={{ fontSize: `${subtitleSize}px` }}>{item.description}</h3>
                        ) : (
                            <p>{item.description}</p>
                        )}
                    </div>
                    <div
                        key={index}
                        className={`slide phone ${index === activeIndex ? 'active' : ''}`}
                        alt={item.name}
                    >

                        <img src={Settings.Domain + "/images/" + item.phoneImage}></img>
                        {titleSize != null ? (
                            <h3 style={{ fontSize: `${titleSize}px` }}>{item.name}</h3>
                        ) : (
                            <h3>{item.name}</h3>
                        )}

                        {subtitleSize != null ? (
                            <h3 style={{ fontSize: `${subtitleSize}px` }}>{item.description}</h3>
                        ) : (
                            <p>{item.description}</p>
                        )}
                    </div>
                    </>
                    
                ))}
            </div>
        </div>
    );
};

export default CustomSlider;
