import React, { useEffect, useState } from 'react'

import "./Register.css"
import { Link } from 'react-router-dom'
import Settings from "../../Components/Settings.json"
import { useAuth } from '../../Components/AuthProvider/AuthProvider'

import CustomizedSnackbars from "../../Components/Snackbar/Snackbar"
import { Button, FormControl, TextField } from '@mui/material'

function Register() {

  const [firstName, setFirstName] = useState("")
  const [lastname, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [repeatPassword, setRepeatPassword] = useState("")


  const [responseMessage, setResponseMessage] = useState()
  const [messageType, setMessageType] = useState()
  const [snackBarOpen, setSnackBarOpen] = useState(false)
  const [key, setKey] = useState(0)

  const opensnackbar = (type, message) => {
    setSnackBarOpen(true)
    setMessageType(type)
    setResponseMessage(message)
    setKey(key + 1)
  }


  const { user, login } = useAuth()

  const register = (e) => {
    e.preventDefault()

    fetch(Settings.Domain + Settings.RegisterUser, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        firstName: firstName,
        lastName: lastname,
        email: email,
        password: password,
        repeatPassword: repeatPassword,
      })
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {

        if (data.error) {
          opensnackbar("error", data.error)
          return
        }

        if (data.success) {
          const response = data.response

          login(response.token)

          window.location.replace(window.location.origin)
        }
      })
      .catch((error) => {
        console.error("Fetch error:", error);
      });

  }

  useEffect(() => {
    if (user.loaded === false) return

    if (user.loggedIn === true) {
      window.location.href = window.location.origin
    }
  }, [user.loaded])

  return (
    <div className='form-holder'>
      <form onSubmit={register} className='login'>
        <h1 className='form-title'>Register</h1>
        <FormControl className='login-modal-inout-holder'>
          <TextField
            fullWidth
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            label='Firstname'
            type='text'
            placeholder='John'
            variant="standard"
          />
          <br></br>
          <TextField
            fullWidth
            value={lastname}
            onChange={(e) => setLastName(e.target.value)}
            label='Lastname'
            type='text'
            placeholder='Doe'
            variant="standard"
          />
          <br></br>
          <TextField
            placeholder='example@gmail.com'
            type="email"
            variant="standard"
            fullWidth
            value={email}
            label='Email'
            onChange={(e) => setEmail(e.target.value)}
          />
          <br></br>
          <TextField
            placeholder='Password'
            type="password"
            variant="standard"
            fullWidth
            value={password}
            label='Password'
            onChange={(e) => setPassword(e.target.value)}
          />
          <br></br>
          <TextField
            placeholder='Repeat password'
            type="password"
            variant="standard"
            fullWidth
            value={repeatPassword}
            label='Password'
            onChange={(e) => setRepeatPassword(e.target.value)}
          />
        </FormControl>
        <br></br>
        <Button color="primary" onClick={register} variant="outlined">Register</Button>
        <br></br>
        <span>Already a member? <Link to="/Login">Login</Link></span>
      </form>
      <CustomizedSnackbars messageType={messageType} message={responseMessage} openSnackbar={snackBarOpen} key={key} />
    </div>
  )
}



export default Register