import { Button, FormControl, MenuItem, Select, TextField } from '@mui/material'
import React, { useState } from 'react'

import Settings from "../../../Settings.json"

import { useAuth } from "../../../AuthProvider/AuthProvider"
import CustomizedSnackbars from "../../../Snackbar/Snackbar"

import "./EditUser.css"

function EditUser() {

  const { user } = useAuth()

  const [responseMessage, setResponseMessage] = useState()
  const [messageType, setMessageType] = useState()
  const [snackBarOpen, setSnackBarOpen] = useState(false)
  const [key, setKey] = useState(0)

  const [showDetails, setShowDetails] = useState(false)

  const [searchElement, setSearchElement] = useState("")


  const [firstname, setFirstname] = useState("")
  const [lastname, setLastname] = useState("")
  const [email, setEmail] = useState("")
  const [verified, setVerfified] = useState("")
  const [password, setPassword] = useState("")
  const [memberShip, setMembership] = useState("")
  const [phone, setPhone] = useState("")
  const [userId, setUserId] = useState(0)

  const [verfifiedListOpen, setVerfifiedListOpen] = useState(false)
  const [membershipListOpen, setMembershipListOpen] = useState(false)


  const membershipList = {
    "Member": 0,
    "Admin": 1
  };

  const verifiedList = {
    "No": 1,
    "Yes": 2
  }


  function getKeyFromValue(object, value) {
    for (const key in object) {
      if (object[key] === value) {
        return key;
      }
    }
    return null;
  }


  const opensnackbar = (type, message) => {
    setSnackBarOpen(true)
    setMessageType(type)
    setResponseMessage(message)
    setKey(key + 1)
  }



  const saveUser = () => {
    fetch(Settings.Domain + Settings.SaveUser, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        firstName: firstname,
        lastName: lastname,
        email: email,
        password: password,
        memberShip: memberShip,
        verified: verified,
        phone: phone,
        id: userId,
        token: user.token
      })
    })
      .then(response => {
        return response.json()
      })
      .then(data => {
        if (data.success) {
          opensnackbar("success", data.response)
          return
        }

        if (data.error) {
          opensnackbar("error", data.error)
          return
        }
      })
      .catch(err => {
        console.log(err);
      })
  }


  const loadUser = () => {
    fetch(Settings.Domain + Settings.GetUser, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: user.token,
        searchElement: searchElement
      })
    })
      .then(response => {
        return response.json()
      })
      .then(data => {
        if (data.success) {
          opensnackbar("success", "User loaded")

          data = data.response

          setFirstname(data.firstName)
          setLastname(data.lastName)
          setEmail(data.email)
          setPassword(data.password)
          setVerfified(data.verified === 0 ? "Yes" : "No")
          setMembership(data.memberShip)
          setPhone(data.phoneNumber)
          setUserId(data.id)

          setShowDetails(true)
        } else if (data.error) {
          opensnackbar("error", data.error)
        }
      })
  }

  return (
    <div className='container' style={{ margin: 20, height: "fit-content" }}>
      {showDetails != false  ? (
        <div className='image-uploader'>
          <TextField
            className='user-search-result'
            value={firstname}
            fullWidth
            onChange={(e) => {
              setFirstname(e.target.value)
            }}
            label={"Firstname"}
            variant="standard"
          />


          <TextField
            className='user-search-result'
            value={lastname}
            fullWidth
            onChange={(e) => {
              setLastname(e.target.value)
            }}
            label={"Lastname"}
            variant="standard"
          />

          <TextField
            className='user-search-result'
            value={email}
            fullWidth
            onChange={(e) => {
              setEmail(e.target.value)
            }}
            label={"Email"}
            variant="standard"
          />

          <TextField
            className='user-search-result'
            value={password}
            fullWidth
            onChange={(e) => {
              setPassword(e.target.value)
            }}
            label={"Password"}
            variant="standard"
          />

          <FormControl
            fullWidth
            className='user-search-result'
          >
            <Select
              value={membershipList[memberShip]}
              open={membershipListOpen}
              onClose={() => {
                setMembershipListOpen(false)
              }}
              onOpen={() => {
                setMembershipListOpen(true)
              }}
              fullWidth
              onChange={(e) => {
                setMembership(getKeyFromValue(membershipList, e.target.value))
              }}
              label="Membership"
              variant="standard"
              aria-label="Membership"
            >
              <MenuItem value={0} fullWidth>Member</MenuItem>
              <MenuItem value={1} fullWidth>Admin</MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth className='user-search-result'>
            <Select
              value={verifiedList[verified]}
              open={verfifiedListOpen}
              fullWidth
              onClose={() => {
                setVerfifiedListOpen(false)
              }}
              onOpen={() => {
                setVerfifiedListOpen(true)
              }}
              onChange={(e) => {
                setVerfified(getKeyFromValue(verifiedList, e.target.value))
              }}
              label={"Verfied"}
              variant="standard"
            >
              <MenuItem value={0} fullWidth>No</MenuItem>
              <MenuItem value={1} fullWidth>Yes</MenuItem>
            </Select>
          </FormControl>

          <TextField
            className='user-search-result'
            value={phone}
            fullWidth
            onChange={(e) => {
              setPhone(e.target.value)
            }}
            label={"Phone"}
            variant="standard"
          />

          <Button color="primary" variant="outlined" style={{ margin: "auto", width: "250px", height: "50px" }} onClick={saveUser}>Save</Button>
        </div>
      ) : (
      <div className='search-car-label'>
        <TextField
          value={searchElement}
          fullWidth
          onChange={(e) => {
            setSearchElement(e.target.value)
          }}
          label={"Phone or Email"}
          variant="outlined"
        />
        <br></br>
        <Button color="primary" variant="outlined" style={{ margin: "auto", width: "250px", height: "50px" }} onClick={loadUser}>Search</Button>
      </div>
      )}
      <CustomizedSnackbars messageType={messageType} message={responseMessage} openSnackbar={snackBarOpen} key={key} />
    </div>
  )
}

export default EditUser