import React, { useEffect, useState } from 'react';
import { DataGrid } from "@mui/x-data-grid"
import dayjs from "dayjs"

import Settings from '../../Components/Settings.json';

import { useAuth } from "../../Components/AuthProvider/AuthProvider"

import "./Orders.css"
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, InputAdornment, InputLabel, TextField, FormControl, OutlinedInput } from '@mui/material';

function Orders() {

    const { user } = useAuth()

    const [orders, setOrders] = useState([])
    const [carList, setCarList] = useState([])

    const [carPlaces, setCarPlaces] = useState([])

    const [modalOpen, setModalOpen] = useState(false)
    const [rows, setRows] = useState([])

    const [modalData, setModalData] = useState({})
    const [itemsList, setItemList] = useState([])
    const [itemModalOpen, setItemModalOpen] = useState(false)

    const [carDiscounts, setCarDiscounts] = useState([])

    const loadCarDiscounts = () => {
        fetch(Settings.Domain + Settings.GetCarDiscounts, {
            method: "GET",
        })
            .then((response) => {
                return response.json();
            })
            .then(data => {
                if (data.success) {
                    setCarDiscounts(data.response)
                }
            })
            .catch(err => {
                console.log(err);
            })
    }


    const columns = [
        { field: 'id', headerName: 'Order ID', width: 70 },
        { field: 'car_name', headerName: 'Car name', width: 130 },
        { field: 'pickUp', headerName: 'Pick Up', width: 130 },
        { field: 'dropOff', headerName: 'Drop Off', width: 130 },
        {
            field: 'type',
            headerName: 'Type',
            width: 130,
            renderCell: (params) => getOrderType(params.row.type) // Call the getOrderType function
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 130,
            renderCell: (params) => (
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                        loadOrderModal(params.row.id);
                        setModalOpen(true)
                    }}
                >
                    View
                </Button>
            ),
        },
    ];


    const loadItems = () => {
        fetch(Settings.Domain + Settings.LoadCarItem, {
            method: "GET",
        })
            .then(response => {
                return response.json()
            })
            .then(data => {
                if (data.success) {
                    setItemList(data.response)
                }
            })
            .catch(error => {
                console.log(error);
            })
    }

    const getOrderType = (id) => {
        if (id === 0) {
            return <Chip label="QUEUE" variant="outlined" />;
        } else if (id === 1) {
            return <Chip label="ACCEPTED" color="success" variant="outlined" />;
        } else if (id === 2) {
            return <Chip label="PROGRESS" color="primary" variant="outlined" />;
        } else if (id === 3) {
            return <Chip label="COMPLETED" color="warning" variant="outlined" />;
        } else if (id === 4) {
            return <Chip label="CANCELED" color="error" variant="outlined" />;
        }
    }

    const getOrderPrice = () => {
        var service = 0

        if (modalData.order.pickUp != modalData.order.dropOff) {
            service = 80
        }

        const carPlaces = JSON.parse(modalData.carData.car_data[0].car_places)

        const placeFound = carPlaces.find((data, index) => data.id == modalData.order.pickUp)

        if (placeFound.amount === 0) {
            service = 80
        }

        var rentalPrice = modalData.carData.car[0].car_price * modalData.order.duretation


        for (var i = 0; i < carDiscounts.length; i++) {
            const selectedDiscount = carDiscounts[i]

            if (modalData.order.duretation >= selectedDiscount.min_day  && modalData.order.duretation <= selectedDiscount.max_day) {
                rentalPrice = (rentalPrice) - ((rentalPrice) * (selectedDiscount.amount/100))
                break
            } else if (selectedDiscount.max_day === ">") {
                rentalPrice = (rentalPrice) - ((rentalPrice) * (selectedDiscount.amount/100))
                break
            }
        }

        var itemsPrice = 0

        if (modalData.items) {
            modalData.items.forEach((item) => {
                itemsPrice = itemsPrice + item.item_price * item.amount
            })
        }



        return {
            itemsPrice: itemsPrice,
            rentalPrice: rentalPrice,
            service_fee: service,
            car_price: modalData.carData.car[0].car_price,
            totalDays: modalData.order.duretation
        }
    }

    const loadOrderModal = (order_id) => {
        const orderData = orders.find((order) => order.order.order_id == order_id)

        if (!orderData) return

        const carFound = findCar(orderData.order.car_id);

        if (!carFound) return


        setModalData({
            order: orderData.order,
            items: orderData.items,
            carData: carFound
        })
    }

    const getPlaceByID = (place_id) => {
        const foundPlace = carPlaces.find(place => place.id === place_id);
        const placeName = foundPlace ? foundPlace.place_name : null;

        return placeName
    }

    const findCar = (car_id) => {

        var car_data

        carList.forEach(car => {

            if (!car.car[0]) {
                return
            }

            if (car.car[0].car_data_id === car_id) {
                car_data = car
            }
        })

        return car_data
    }

    const finalyzeRow = () => {

        setRows([])

        orders.map((orderData, index) => {
            const order = orderData.order
            const carFound = findCar(order.car_id);

            if (!carFound) {
                return
            }
            carFound.order = order
    
            const newRow = {
                id: order.order_id,
                car_name: carFound.car[0].car_name,
                pickUp: getPlaceByID(order.pickUp),
                dropOff: getPlaceByID(order.dropOff),
                type: order.type,
                action: "button"
            }

            setRows((oldData) => [...oldData, newRow]);
        });

        setRows((oldData) => {
            return oldData.reverse()
        })
    }


    const loadCars = () => {
        const carIds = orders.map(data => data.order.car_id);

        carIds.map((car_id, index) => {
            fetch(Settings.Domain + Settings.GetCar, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    car_id: car_id
                }),
            })
                .then(response => {
                    return response.json()
                })
                .then(data => {
                    if (data.success) {
                        const carData = {
                            car: data.response.car,
                            car_data: data.response.car_data,
                        };

                        setCarList((oldData) => [...oldData, carData]);
                    }
                })
        })
    }

    const getItemByID = (id) => {
        const itemFound = itemsList.find((item) => item.id === id)

        if (itemFound) {
            return itemFound
        }

        return null
    }

    const loadOrders = () => {

        if (!user.token) return

        fetch(Settings.Domain + Settings.GetUserOrders, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: user.token
            }),
        })
            .then(response => {
                if (!response.ok) {
                    throw Error("Response was not ok")
                }

                return response.json()
            })
            .then(data => {
                if (data.success) {
                    const orders = data.response.map(order => {
                      const orderItemsList = JSON.parse(order.items);
          
                      const itemList = orderItemsList.map(item => {
                        const itemFound = getItemByID(item.id);
          
                        return itemFound ? {
                          id: itemFound.id,
                          item_name: itemFound.item_name,
                          item_price: itemFound.item_price,
                          amount: item.amount
                        } : null;
                      }).filter(Boolean);
          
                      return {
                        order: order,
                        items: itemList
                      };
                    });
          
                    setOrders(orders);
                }
            })
    }

    const loadCarPlaces = () => {
        fetch(Settings.Domain + Settings.GetPlaces, {
            method: 'GET',
        })
            .then(response => {
                return response.json()
            })
            .then(data => {
                if (data.success) {
                    setCarPlaces(data.response)
                }
            })
    }

    useEffect(() => {
        if (user.loggedIn === false) {
          window.location.href = window.location.origin
          return
        }

        loadItems();
        loadCarDiscounts();
        loadCarPlaces();
        loadOrders();
      }, [user.loggedIn])
    
      useEffect(() => {
        loadCars();
      }, [orders])
    
      useEffect(() => {
        finalyzeRow();
      }, [carList])

    return (
        <div className='container' style={{ margin: "200px 0" }}>
            <div style={{ background: "white", width: 'fit-content', maxWidth: "100%", margin: "auto" }}>
                <div style={{ maxHeight: 400, width: '100%', height: "fit-content" }}>
                    <DataGrid
                        style={{ width: "100%", margin: "auto" }}
                        rows={rows}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 5 },
                            },
                        }}
                        pageSizeOptions={[5, 10]}
                        disableRowSelectionOnClick={true}
                    />
                </div>
            </div>
            <Dialog
                fullWidth
                open={modalOpen}>
                {modalData.order && (
                    <DialogContent style={{ display: "flex", flexDirection: "column" }}>

                        <DialogContentText>
                            ORDER #{modalData.order.order_id} {getOrderType(modalData.order.type)}
                        </DialogContentText>
                        <br></br>
                        <TextField
                            value={modalData.carData.car.car_name}
                            label="Name"
                            disabled
                            variant="standard"
                        />
                        <br></br>
                        <TextField
                            value={getPlaceByID(modalData.order.pickUp)}
                            label="Pick Up Location"
                            disabled
                            variant="standard"
                        />
                        <br></br>
                        <TextField
                            value={getPlaceByID(modalData.order.dropOff)}
                            label="Drop Off Location"
                            disabled
                            variant="standard"
                        />
                        <br></br>
                        <TextField
                            value={modalData.order.start_date}
                            label="Pick Date"
                            disabled
                            variant="standard"
                        />
                        <br></br>
                        <TextField
                            value={modalData.order.end_date}
                            label="Drop Date"
                            disabled
                            variant="standard"
                        />
                        <br></br>
                        <TextField
                            value={modalData.order.phone}
                            label="Phone"
                            disabled
                            variant="standard"
                        />
                        <br></br>
                        <TextField
                            value={modalData.order.email}
                            label="Email"
                            disabled
                            variant="standard"
                        />
                        <br></br>
                        <TextField
                            value={getOrderPrice().service_fee + user.currency}
                            label="Service Fee"
                            disabled
                            variant="standard"
                        />
                        <br></br>
                        <TextField
                            value={getOrderPrice().rentalPrice + user.currency}
                            label="Rental Price"
                            disabled
                            variant="standard"
                        />
                        <br></br>
                        <TextField
                            value={getOrderPrice().itemsPrice + user.currency}
                            label="Item Price"
                            disabled
                            variant="standard"
                        />
                        <br></br>
                        <TextField
                            value={getOrderPrice().service_fee + getOrderPrice().rentalPrice + getOrderPrice().itemsPrice + user.currency}
                            label="Total Price"
                            disabled
                            variant="standard"
                        />
                        <br></br>
                        <TextField
                            value={modalData.order.message}
                            label="Message"
                            disabled
                            variant="standard"
                        />

                        <br></br>

                        <Button color="primary" variant="outlined" sx={{ height: 80 }} onClick={() => {
                            setItemModalOpen(true)
                        }}>Item List</Button>

                    </DialogContent>
                )}
                <DialogActions>
                    <Button onClick={(e) => {
                        setModalOpen(false);
                    }}>Close</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                fullWidth
                open={itemModalOpen}>
                {modalData.items && (
                    <DialogContent style={{ display: "flex", flexDirection: "column" }}>
                        {
                            modalData.items.map((item, index) => (
                                <FormControl sx={{ m: 1, paddingRight: 0 }} variant="outlined" fullWidth key={index}>
                                    <InputLabel>{item.item_name}</InputLabel>
                                    <OutlinedInput
                                        fullWidth
                                        disabled
                                        style={{ paddingRight: "0", border: "none" }}
                                        type={"text"}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <InputLabel sx={{ width: "100px" }}>{item.item_price + user.currency}</InputLabel>
                                                <OutlinedInput
                                                    style={{ paddingRight: "0", border: "none" }}
                                                    type='tel'
                                                    disabled
                                                    value={item.amount}
                                                />
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            ))
                        }
                    </DialogContent>
                )}
                <DialogActions>
                    <Button onClick={(e) => {
                        setItemModalOpen(false);
                    }}>Close</Button>
                </DialogActions>
            </Dialog>

        </div>
    )
}

export default Orders