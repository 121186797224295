import React, { useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './App.css';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';

import Index from "./Pages/Index/Index"
import Register from './Pages/Register/Register';
import Auth from './Pages/Auth/Auth';
import Login from './Pages/Login/Login';
import Footer from './Components/Footer/Footer';
import CarsList from './Pages/CarsList/CarsList';
import CarData from './Pages/CarData/CarData';

import Navbar from './Components/Navbar/Navbar';
import { AuthProvider, useAuth } from "./Components/AuthProvider/AuthProvider";
import AdminPanel from './Pages/AdminPanel/AdminPanel';
import Verify from './Pages/Verify/Verify';
import Orders from './Pages/Orders/Orders';
import Tours from './Pages/Tours/Tours';
import Settings from './Pages/Settings/Settings';
import Loader from './Components/Loader/Loader';
import ForgotPassword from './Pages/ForgotPassword/ForgotPassword';
import ResetPassword from './Pages/ResetPassword/ResetPassword';
import ResponsiveAppBar from './Components/NewNavbar/Navbar';
import TourList from './Pages/TourList/TourList';
import PageNotFound from './Pages/404/404';
import Test from './Pages/test/test';
import SocialMedia from './Components/SocialMedia/SocialMedia';



const App = () => {
  return (
    <AuthProvider>
      <AppContent />
    </AuthProvider>
  );
}

function hexToRgb(hex) {
  // Remove the hash character if it exists
  hex = hex.replace(/^#/, '');

  // Parse the hex value to obtain the red, green, and blue components
  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return `rgb(${r}, ${g}, ${b})`;
}

const AppContent = () => {
  const { user } = useAuth();

  const primaryColor = hexToRgb(user.primaryColor); // Convert primaryColor to RGB
  const secondaryColor = hexToRgb(user.secondaryColor); // Convert secondaryColor to RGB

  const theme = createTheme({
    palette: {
      primary: {
        main: primaryColor,
      },
      secondary: {
        main: secondaryColor,
      },
    },
  });


  return (
    <ThemeProvider theme={theme}>
      <Router>
        <ResponsiveAppBar />
        <Loader />
        <SocialMedia></SocialMedia>
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="/auth" element={<Auth />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/carlist" element={<CarsList />} />
          <Route path="/carlist/car/:carId" element={<CarData />} />
          <Route path="/adminPanel/:type?/:action?/:id?" element={<AdminPanel />} />
          <Route path="/verify" element={<Verify />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/tours" element={<Tours />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/forgotPassword" element={<ForgotPassword />} />
          <Route path="/resetPassword" element={<ResetPassword />} />
          <Route path="/tourList/:id" element={<TourList />} />
          <Route path="/test" element={<Test />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
        <Footer />
      </Router>
    </ThemeProvider>
  );
}

export default App;
