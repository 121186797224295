import React, { useEffect, useState } from 'react'
import { useAuth } from '../../Components/AuthProvider/AuthProvider'
import { TextField, Button, useRadioGroup } from '@mui/material'

import CustomizedSnackbars from "../../Components/Snackbar/Snackbar"

import "./Settings.css"
import MuiPhoneNumber from 'material-ui-phone-number'
import SettingsModule from "../../Components/Settings.json"

function Settings() {

    const { user } = useAuth()


    const [responseMessage, setResponseMessage] = useState()
    const [messageType, setMessageType] = useState()
    const [snackBarOpen, setSnackBarOpen] = useState(false)
    const [key, setKey] = useState(0)


    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [verified, setVerfieid] = useState(false)
    const [phone, setPhone] = useState("")

    const opensnackbar = (type, message) => {
        setSnackBarOpen(true)
        setMessageType(type)
        setResponseMessage(message)
        setKey(key + 1)
    }


    const saveData = () => {
        fetch(SettingsModule.Domain + SettingsModule.UpdateProfile, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                firstName: firstName,
                lastName: lastName,
                email: email,
                phoneNumber: phone,
                token: user.token
            })
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                return response.json();
            })
            .then((data) => {
                if (data.success) {
                    opensnackbar("success", data.response)
                } else {
                    opensnackbar("error", data.error)
                }
            })
            .catch((error) => {
                console.error("Fetch error:", error);
            });

    }

    const sendEmailVerification = () => {
        fetch(SettingsModule.Domain + SettingsModule.SendEmailVerification, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                token: user.token
            })
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.success) {
                    opensnackbar("success", data.response)
                } else {
                    opensnackbar("error", data.error)
                }
            })
            .catch((error) => {
                console.error("Fetch error:", error);
            });
    }



    useEffect(() => {
        if (user.loaded === false) return

        if (user.loggedIn === false) {
            window.location.href = window.location.origin
        } else {
            setFirstName(user.firstName)
            setLastName(user.lastName)
            setEmail(user.email)
            setVerfieid(user.verified)
            setPhone(user.phone.toString())
        }
    }, [user.loaded])

    return (
        <div className='container' style={{margin: "200px 0"}}>
            <div className='settings-profile'>
                <h1>Settings</h1>
                <TextField
                    fullWidth
                    variant="standard"
                    value={firstName}
                    label={"Firstname"}
                    onChange={(e) => {
                        setFirstName(e.target.value)
                    }}
                />
                <TextField
                    fullWidth
                    variant="standard"
                    value={lastName}
                    label={"Lastname"}
                    onChange={(e) => {
                        setLastName(e.target.value)
                    }}
                />
                <TextField
                    fullWidth
                    variant="standard"
                    value={email}
                    label={"Email"}
                    color="error"
                    focused={!verified}
                    onChange={(e) => {
                        setEmail(e.target.value)
                    }}
                />
                {!user.verified && (
                    <span style={{ textAlign: "start", width: "100%", margin: 0, padding: 0, color: "red", cursor: "pointer" }} onClick={sendEmailVerification}>Send verification link</span>
                )}
                <MuiPhoneNumber
                    defaultCountry={'ge'}
                    onChange={(e) => {
                        setPhone(e)
                    }}
                    margin="normal"
                    fullWidth
                    variant="standard"
                    value={phone}
                />
                <br></br>
                <span style={{ textAlign: "center", width: "70%", margin: 0, padding: 0, color: "orange", cursor: "pointer" }}>WARNING: New emails also need to proceed the verification process </span>
                <br></br>
                <Button variant="outlined" onClick={saveData}>Save</Button>
            </div>
            <CustomizedSnackbars messageType={messageType} message={responseMessage} openSnackbar={snackBarOpen} key={key} />
        </div>
    )
}

export default Settings