import React, { useEffect, useState } from 'react'

import { Autocomplete, Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Divider, Pagination, Skeleton, Slider, Switch, TextField, Typography } from '@mui/material';


import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import TimeToLeaveIcon from '@mui/icons-material/TimeToLeave';
import LuggageIcon from '@mui/icons-material/Luggage';
import ShutterSpeedIcon from '@mui/icons-material/ShutterSpeed';
import MinorCrashIcon from '@mui/icons-material/MinorCrash';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import ChairIcon from '@mui/icons-material/Chair';

import Settings from '../Settings.json'

import "./CardComponent.css"


function CardComponent({ car }) {

    const [carFeulList, setCarFeulList] = useState([])

    const getFeulNameById = (id) => {
        for (let i = 0; i < carFeulList.length; i++) {
            if (carFeulList[i].id == id) {
                return carFeulList[i].fuel_name
            }
        }
    }

    const getReleaseDate = (date) => {
        if (typeof date === 'string') {
            return JSON.parse(date).join(' - ');
        } else {
            return date.join(' - ')
        }
    }

    useEffect(() => {
        fetch(Settings.Domain + Settings.LoadFeuls, {
            method: 'GET',
        })
            .then(res => { return res.json() })
            .then(data => {
                if (data.success) {
                    setCarFeulList(data.response)
                }
            })
    }, [])


    return (
        <Card className='car-card'>
            <CardMedia
                component="img"
                alt={car.name}
                height="240"
                image={car.image}
                sx={{
                    minHeight: 300,
                    objectFit: "contain"
                }}
            />
            <CardContent sx={{ height: 260 }} className='car-info'>
                <Typography gutterBottom variant="h5" component="div" sx={{ width: "100%", display: "flex" }}>
                    {car.name}
                </Typography>
                <div className='card-info-holder' >
                    <Typography variant="body3" color="text.secondary" className='card-info'>
                        <CalendarMonthIcon></CalendarMonthIcon>
                        <p>{getReleaseDate(car.car_release)}</p>
                    </Typography>
                    <Typography variant="body3" color="text.secondary" className='card-info'>
                        <TimeToLeaveIcon></TimeToLeaveIcon>
                        <p>{car.automatic == 1 ? "Automatic" : "Mechanical"}</p>
                    </Typography>
                    <Typography variant="body3" color="text.secondary" className='card-info'>
                        <ChairIcon></ChairIcon>
                        <p>{car.seats}</p>
                    </Typography>
                    <Typography variant="body3" color="text.secondary" className='card-info'>
                        <LuggageIcon></LuggageIcon>
                        <p>{car.car_luggage}L</p>
                    </Typography>
                    <Typography variant="body3" color="text.secondary" className='card-info'>
                        <WhatshotIcon></WhatshotIcon>
                        <p>{car.burn}</p>
                    </Typography>
                    <Typography variant="body3" color="text.secondary" className='card-info'>
                        <LocalGasStationIcon></LocalGasStationIcon>
                        <p>{getFeulNameById(car.feul)}</p>
                    </Typography>
                    <Typography variant="body3" color="text.secondary" className='card-info'>
                        <ShutterSpeedIcon></ShutterSpeedIcon>
                        <p>{car.engine}</p>
                    </Typography>
                    <Typography variant="body3" color="text.secondary" className='card-info'>
                        <MinorCrashIcon></MinorCrashIcon>
                        <p>{car.characteristics}</p>
                    </Typography>
                </div>
            </CardContent>
            <Divider></Divider>
            <CardActions>
                <Button
                    size="small"
                    variant="outlined"
                    sx={{ width: "80%", height: 50, margin: "20px auto 0 auto", fontWeight: 800 }}
                    onClick={(e) => {
                        window.location.href = window.location.origin + `/carlist/car/${car.id}`
                    }}
                >
                    View
                </Button>
            </CardActions>
        </Card>
    )
}

export default CardComponent