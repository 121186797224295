import React, { useState } from 'react'

import EditUser from "./EditUser/EditUser"
import DeleteUser from "./DeleteUser/DeleteUser"

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TabPanel from "@mui/lab/TabPanel"
import TabContext from '@mui/lab/TabContext';


import "./UserPanel.css"

export default function UserPanel() {

  const [value, setValue] = useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", bgcolor: 'background.paper', margin: "0 0 0 10px" }}>
      <TabContext value={value}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Edit" value={"1"} />
          <Tab label="Remove" value={"2"} />
        </Tabs>
        <TabPanel value="1">
          <EditUser></EditUser>
        </TabPanel>
        <TabPanel value="2">
          <DeleteUser></DeleteUser>
        </TabPanel>
      </TabContext>
    </Box>
  )
}
