import React from 'react'

import "./Footer.css"

import SecurityIcon from '@mui/icons-material/Security';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import InfoIcon from '@mui/icons-material/Info';
import CallIcon from '@mui/icons-material/Call';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import MailIcon from '@mui/icons-material/Mail';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className='footer'>
      <div className='footer-header'>
        <p>{window.location.host}</p>
      </div>
      <div className='footer-list'>
        <div className='footer-items'>
          <div className='footer-item'>
            <InstagramIcon></InstagramIcon>
            <a href="https://www.instagram.com/letsride_geo" target='__blank'>Instagram</a>
          </div>
          <div className='footer-item'>
            <FacebookIcon></FacebookIcon>
            <a href="https://www.facebook.com/profile.php?id=61558817160882" target='__blank'>Facebook</a>
          </div>
        </div>
        <div className='footer-items'>
          <div className='footer-item'>
            <CallIcon></CallIcon>
            <a href='tel:+995511281212'>+995 511 281 212</a>
          </div>
          <div className='footer-item'>
            <CallIcon></CallIcon>
            <a href='tel:+995599904000'>+995 599 904 000</a>
          </div>
          <div className='footer-item'>
            <MailIcon></MailIcon>
            <a href="mailto:support@letsride.ge">support@letsride.ge</a>
          </div>

        </div>
        <div className='footer-items'>
          <div className='footer-item'>
            <InfoIcon></InfoIcon>
            <Link>About Us</Link>
          </div>
          <div className='footer-item'>
            <SecurityIcon></SecurityIcon>
            <Link>Privacy Police</Link>
          </div>
          <div className='footer-item'>
            <PrivacyTipIcon></PrivacyTipIcon>
            <Link>Term Of Service</Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer