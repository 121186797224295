import React, { useEffect, useState } from 'react';

import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import TimeToLeaveIcon from '@mui/icons-material/TimeToLeave';
import EventSeatIcon from '@mui/icons-material/EventSeat';
import LuggageIcon from '@mui/icons-material/Luggage';
import ShutterSpeedIcon from '@mui/icons-material/ShutterSpeed';
import MinorCrashIcon from '@mui/icons-material/MinorCrash';

import Settings from "../../../Settings.json"

import "./EditCar.css";
import { FormControl, Select, Switch, TextField, MenuItem, Box, Snackbar, Button, Autocomplete } from '@mui/material';
import { useAuth } from '../../../AuthProvider/AuthProvider';
import CustomizedSnackbars from '../../../Snackbar/Snackbar';
import MUIRichTextEditor from 'mui-rte';
import { convertToRaw } from 'draft-js';

function EditCar() {

  const [responseMessage, setResponseMessage] = useState()
  const [messageType, setMessageType] = useState()
  const [snackBarOpen, setSnackBarOpen] = useState(false)
  const [key, setKey] = useState(0)

  const opensnackbar = (type, message) => {
    setSnackBarOpen(true)
    setMessageType(type)
    setResponseMessage(message)
    setKey(key + 1)
  }

  const [showDetails, setshowDetails] = useState(false)

  const [carID, setCarID] = useState()

  const { user, login, logout } = useAuth()

  const [carAutomatic, setCarAutomatic] = useState(false)

  const [releaseYearsList, setReleaseYearsList] = useState([])

  const [carName, setCarName] = useState()
  const [pricePerDay, setPricePerDay] = useState()


  const [descriptionToShow, setCarDescriptionToShow] = useState()

  const [carDescription, setCarDescription] = useState()

  const [carCharacteristics, setCarCharacteristics] = useState("");
  const [carEngine, setCarEngine] = useState(4.0)
  const [carFeul, setCarFuel] = useState("Diesel")

  const [carBurn, setCarBurn] = useState([])

  const [carSeats, setCarSeats] = useState()
  const [carLuggage, setCarLuggage] = useState()

  const [releaseYear, setReleaseYear] = useState(2012)
  const [endYear, setEndYear] = useState(new Date().getFullYear())
  const [carFuelsList, setCarFuelsList] = useState([])

  const [carPlaces, setCarPlaces] = useState([])

  const [carBrands, setCarBrands] = useState([])
  const [carBrand, setCarBrand] = useState(1)
  const [keywords, setKeywords] = useState()

  const handleCarPlaceChange = (index, newValue) => {
    const updatedCarPlaces = [...carPlaces];

    if (updatedCarPlaces.length === 0) {
      return
    }

    updatedCarPlaces[index - 1].amount = newValue;

    setCarPlaces(updatedCarPlaces);
  };


  const saveCar = () => {

    const carType = carAutomatic === 1 ? "false" : "true"

    console.log(keywords);

    const carData = {
      car_id: carID,
      car_name: carName,
      car_description: JSON.stringify(carDescription),
      price_per_day: pricePerDay,
      car_automatic: carType,
      car_seats: carSeats,
      engine: carEngine,
      car_feul: carFeul,
      car_luggage: carLuggage,
      car_release: JSON.stringify([releaseYear, endYear]),
      car_places: JSON.stringify(carPlaces),
      brand: carBrand,
      burn: carBurn,
      tags: JSON.stringify(keywords),
      characteristics: carCharacteristics,
      token: user.token
    };

    fetch(Settings.Domain + Settings.EditCar, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(carData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          opensnackbar("success", data.response)
        } else if (data.error) {
          opensnackbar("error", data.error)
        }
      })
      .catch((error) => {
        setMessageType("error")
        setResponseMessage(error)
      })
  };

  const handleEditorChange = (state) => {
    if (state) {
      const contentState = state.getCurrentContent();
      const rawContentState = convertToRaw(contentState);
      setCarDescription(rawContentState);
    }
  };


  const loadCar = () => {
    fetch(Settings.Domain + Settings.GetCar, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        car_id: carID
      })
    })
      .then(response => {
        return response.json()
      })
      .then(data => {
        console.log(data);
        if (data.success) {
          const response = data.response

          const load_car = response.car
          const car_data = response.car_data

          if (!load_car[0]) return

          setPricePerDay(load_car[0].car_price)

          setCarDescriptionToShow(JSON.parse(load_car[0].car_description))
          setCarName(load_car[0].car_name)

          if (!car_data[0]) return

          setCarAutomatic(car_data[0].car_automatic)
          setCarFuel(car_data[0].car_fuel)
          setCarLuggage(car_data[0].car_luggage)
          setCarSeats(car_data[0].car_seats)
          setCarBrand(car_data[0].brand)
          setCarBurn(car_data[0].burn)
          setCarEngine(car_data[0].car_engine)
          setCarCharacteristics(car_data[0].car_characteristics)

          setKeywords(JSON.parse(car_data[0].keywords))

          const car_places = JSON.parse(car_data[0].car_places)

          car_places.map((element, index) => {
            handleCarPlaceChange(element.id, element.amount)
          })

          const release_years = JSON.parse(car_data[0].car_release)

          setReleaseYear(release_years[0])
          setEndYear(release_years[1])

          setshowDetails(true)
        } else if (data.error) {
          opensnackbar("error", data.error)
        }
      })
  }

  useEffect(() => {
    const start_year = 2012
    const end_year = new Date().getFullYear()

    for (let year = start_year; year <= end_year; year++) {
      releaseYearsList.push(year)
    }

    fetch(Settings.Domain + Settings.GetBrands, {
      method: "GET"
    })
      .then(response => {

        if (!response.ok) {
          new Error("Response was not ok")
          return
        }

        return response.json()
      })
      .then(data => {
        if (data.response) {
          setCarBrands(data.response)
        }
      })

    fetch(Settings.Domain + Settings.GetPlaces, {
      method: "GET"
    })
      .then(response => {

        if (!response.ok) {
          new Error("Response was not ok")
          return
        }

        return response.json()
      })
      .then(data => {
        if (data.response) {

          data.response.forEach(element => {
            element.amount = 0
          })

          setCarPlaces(data.response)
        }
      })
      fetch(Settings.Domain + Settings.LoadFeuls, {
        method: "GET"
    })
        .then(response => {
            return response.json()
        })
        .then(data => {
            if (data.success) {
                setCarFuelsList(data.response)
            }
        })
  }, [])

  return (
    <div className='container' style={{ margin: 20, height: "fit-content" }}>
      {showDetails === true ? (
        <div className='image-uploader'>
          <div className='car-rent-information-add'>
            <TextField
              value={carName}
              onChange={(e) => { setCarName(e.target.value) }}
              label="Car name"
            />
            <TextField
              type="number"
              label="Price"
              value={pricePerDay}
              onChange={(e) => { setPricePerDay(e.target.value) }}
            />
            <TextField
              value={carBurn}
              onChange={(e) => { setCarBurn(e.target.value) }}
              label="Burn"
            />
            <Select
              fullWidth
              value={carBrand}
              onChange={(e) => {
                setCarBrand(e.target.value)
              }}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
            >
              {
                carBrands.map((brand, index) => (
                  <MenuItem value={brand.id}>{brand.brand_name}</MenuItem>
                ))
              }
            </Select>
            {
              carPlaces.map((value, index) => (
                <TextField
                  key={value.id}
                  label={value.place_name}
                  type="number"
                  value={value.amount}
                  onChange={(e) => { handleCarPlaceChange(value.id, e.target.value) }}
                />
              ))
            }

            <Box
              sx={{
                width: '100%',
              }}
            >
              <MUIRichTextEditor
                id='description'
                value={JSON.stringify(descriptionToShow)}
                inlineToolbar={true}
                maxLength={1024}
                style={{ height: 300 }}
                onChange={handleEditorChange}
              ></MUIRichTextEditor>
            </Box>
            <Autocomplete
              multiple
              limitTags={2}
              id="multiple-limit-tags"
              freeSolo
              options={keywords}
              value={keywords}
              renderInput={(params) => (
                <TextField {...params} label="Tags" placeholder="Keywords" />
              )}
              onChange={(event, value) => {
                console.log(value);
                setKeywords(value)
              }}
              sx={{ width: '500px' }}
            />
          </div>
          <div className='car-data-list' style={{ background: "transparent", boxShadow: "none" }}>
            <div className='car-detail-data'>
              <div className='icon'>
                <LocalGasStationIcon></LocalGasStationIcon>
                <span>Fuel</span>
              </div>
              <Select
                fullWidth
                value={carFeul}
                id="outlined-number"
                type="text"
                InputLabelProps={{
                  shrink: true,
                }}

                style={{
                  width: 150
                }}
                onChange={(e) => {
                  setCarFuel(e.target.value)
                }}
              >
                {
                  carFuelsList.map((fuel, index) => (
                    <MenuItem value={fuel.id}>{fuel.fuel_name}</MenuItem>
                  ))
                }
              </Select>
            </div>
            <div className='car-detail-data'>
              <div className='icon'>
                <TimeToLeaveIcon></TimeToLeaveIcon>
                <span>Automatic</span>
              </div>
              <Switch
                checked={carAutomatic}
                onChange={(e) => { setCarAutomatic(!carAutomatic) }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </div>
            <div className='car-detail-data'>
              <div className='icon'>
                <EventSeatIcon></EventSeatIcon>
                <span>Seats</span>
              </div>
              <TextField
                value={carSeats}
                id="outlined-number"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  min: 0,
                  inputMode: 'numeric',
                  pattern: '[0-9]*',
                }}
                style={{
                  height: 80,
                  width: 150
                }}
                onChange={(e) => {
                  setCarSeats(e.target.value)
                }}
              />
            </div>
            <div className='car-detail-data'>
              <div className='icon'>
                <ShutterSpeedIcon></ShutterSpeedIcon>
                <span>Engine</span>
              </div>
              <TextField
                value={carEngine}
                id="outlined-number"
                type="text"
                InputLabelProps={{
                  shrink: true,
                }}
                style={{
                  height: 80,
                  width: 150
                }}
                onChange={(e) => {
                  setCarEngine(e.target.value)
                }}
              />
            </div>
            <div className='car-detail-data'>
              <div className='icon'>
                <MinorCrashIcon></MinorCrashIcon>
                <span>Characteristics</span>
              </div>
              <TextField
                value={carCharacteristics}
                id="outlined-number"
                type="string"
                InputLabelProps={{
                  shrink: true,
                }}
                style={{
                  height: 80,
                  width: 150
                }}
                onChange={(e) => {
                  setCarCharacteristics(e.target.value)
                }}
              />
            </div>
            <div className='car-detail-data'>
              <div className='icon'>
                <LuggageIcon></LuggageIcon>
                <span>Luggage</span>
              </div>
              <TextField
                value={carLuggage}
                id="outlined-number"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  min: 0,
                  inputMode: 'numeric',
                  pattern: '[0-9]*',
                }}
                style={{
                  height: 80,
                  width: 150
                }}
                onChange={(e) => {
                  setCarLuggage(e.target.value)
                }}
              />
            </div>
            <div className='car-detail-data'>
              <div className='icon'>
                <CalendarMonthIcon></CalendarMonthIcon>
                <span>Release</span>
              </div>
              <div style={{ display: "flex" }}>
                <FormControl>
                  <Select style={{ width: 150 }} value={releaseYear} onChange={(e) => { setReleaseYear(e.target.value) }}>
                    {releaseYearsList.map((year) => (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl>
                  <Select style={{ width: 150 }} value={endYear} onChange={(e) => { setEndYear(e.target.value) }}>
                    {releaseYearsList.map((year) => (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
          <Button color="primary" variant="outlined" style={{ margin: "auto", width: 250, height: 50 }} onClick={saveCar}>Save</Button>
        </div>
      ) : (
        <div className='search-car-label'>
          <TextField
            fullWidth
            label="Car ID"
            value={carID}
            onChange={(e) => { setCarID(e.target.value) }}
          />
          <br></br>
          <Button color="primary" variant="outlined" style={{ margin: "auto", width: "250px", height: "50px" }} onClick={loadCar}>Search</Button>
        </div>
      )}
      <CustomizedSnackbars messageType={messageType} message={responseMessage} openSnackbar={snackBarOpen} key={key} />
    </div>
  )
}

export default EditCar