import React, { useEffect, useState } from 'react'

import DeleteIcon from '@mui/icons-material/Delete';

import "./CarItems.css"
import { useAuth } from '../../../AuthProvider/AuthProvider'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';

import CustomizedSnackbars from '../../../Snackbar/Snackbar';

import Settings from "../../../Settings.json"

function CarItems() {

    const { user } = useAuth()


    const [items, setItems] = useState([])
    const [itemAddModal, setItemAddModal] = useState(false)

    const currency = user.currency
    const [responseMessage, setResponseMessage] = useState()
    const [messageType, setMessageType] = useState()
    const [snackBarOpen, setSnackBarOpen] = useState(false)
    const [key, setKey] = useState(0)

    const [itemName, setItemName] = useState("")
    const [itemPrice, setItemPrice] = useState(0)


    const opensnackbar = (type, message) => {
        setSnackBarOpen(true)
        setMessageType(type)
        setResponseMessage(message)
        setKey(key + 1)
    }

    const loadItems = () => {
        fetch(Settings.Domain + Settings.LoadCarItem, {
            method: "GET",
        })
            .then(response => {
                return response.json()
            })
            .then(data => {
                if (data.success) {
                    setItems(data.response)
                }

                if (data.error) {

                }
            })
            .catch(error => {
                console.log(error);
            })
    }

    const addItem = () => {
        fetch(Settings.Domain + Settings.AddCarItem, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                token: user.token,
                item_name: itemName,
                item_price: itemPrice
            })
        })
            .then(response => {
                return response.json()
            })
            .then(data => {
                if (data.success) {
                    opensnackbar("success", "Item added")
                    loadItems()
                }

                if (data.error) {
                    opensnackbar("error", data.error)
                }
            })
            .catch(error => {
                console.log(error);
            })
    }

    const deleteItem = (itemId) => {
        fetch(Settings.Domain + Settings.DeleteCarItem, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                itemId: itemId,
                token: user.token
            })
        })
            .then(response => {
                return response.json()
            })
            .then(data => {
                if (data.success) {
                    opensnackbar("success", "Item deleted")
                    loadItems()
                }

                if (data.error) {
                    opensnackbar("error", data.error)
                }
            })
            .catch(error => {
                console.log(error);
            })
    }

    useEffect(() => {
        loadItems()
    }, [])

    return (
        <div className='container' style={{ margin: 20, height: "fit-content" }}>
            <div className='car_item_holder'>
                {
                    items.map((item, index) => (
                        <div className='item'>
                            <p className='item_name'>{item.item_name}</p>
                            <p className='item_price'>{item.item_price + " " + currency}</p>
                            <Button className='delete_button' onClick={(e) => {
                                    deleteItem(item.id)
                                }}>
                                <DeleteIcon></DeleteIcon>
                            </Button>
                        </div>
                    ))
                }
                <div className='item add_form_opener'>
                    <Button className='add_item' onClick={(e) => { setItemAddModal(true) }}>Add Item</Button>
                </div>
            </div>
            <Dialog open={itemAddModal} onClose={(e) => {
                setItemAddModal(false)
            }}>
                <DialogTitle>Items</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="normal"
                        label="Item name"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={itemName}
                        onChange={(e) => {
                            setItemName(e.target.value)
                        }}
                    />
                    <TextField
                        autoFocus
                        margin="normal"
                        label="Item price"
                        type="number"
                        fullWidth
                        variant="standard"
                        value={itemPrice}
                        onChange={(e) => {
                            setItemPrice(e.target.value)
                        }}
                    />
                </DialogContent>

                <DialogActions>
                    <Button color='error' onClick={(e) => {
                        setItemAddModal(false)
                    }}>Cancel</Button>
                    <Button onClick={addItem}>Add</Button>
                </DialogActions>
            </Dialog>
            <CustomizedSnackbars messageType={messageType} message={responseMessage} openSnackbar={snackBarOpen} key={key} />
        </div>
    )
}

export default CarItems