import React, { useState } from 'react'

import Settings from "../../../Settings.json"

import { useAuth } from "../../../AuthProvider/AuthProvider"
import CustomizedSnackbars from "../../../Snackbar/Snackbar"
import { Button, TextField } from '@mui/material'

function DeleteUser() {

  const { user } = useAuth()

  const [responseMessage, setResponseMessage] = useState()
  const [messageType, setMessageType] = useState()
  const [snackBarOpen, setSnackBarOpen] = useState(false)
  const [key, setKey] = useState(0)

  const [searchElement, setSearchElement] = useState("")

  const opensnackbar = (type, message) => {
    setSnackBarOpen(true)
    setMessageType(type)
    setResponseMessage(message)
    setKey(key + 1)
  }

  const deleteUser = () => {
    fetch(Settings.Domain + Settings.DeleteUser, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: user.token,
        searchElement: searchElement
      })
    })
      .then(response => {
        return response.json()
      })
      .then(data => {
        if (data.success) {
          opensnackbar("success", data.response)
          return
        }

        if (data.error) {
          opensnackbar("error", data.error)
          return
        }
      })
      .catch(err => {
        console.log(err);
      })
  }


  return (
    <div className='container' style={{ margin: 20, height: "fit-content" }}>
      <div className='search-car-label'>
        <TextField
          value={searchElement}
          fullWidth
          onChange={(e) => {
            setSearchElement(e.target.value)
          }}
          label={"Phone or Email"}
          variant="outlined"
        />
        <br></br>
        <Button color="primary" variant="outlined" style={{ margin: "auto", width: "250px", height: "50px" }} onClick={deleteUser}>Delete</Button>
      </div>
      <CustomizedSnackbars messageType={messageType} message={responseMessage} openSnackbar={snackBarOpen} key={key} />
    </div>
  )
}

export default DeleteUser