import React, { useState } from 'react'

import "./RemoveCar.css"
import { Button, TextField } from '@mui/material'
import { useAuth } from '../../../AuthProvider/AuthProvider'

import Settings from "../../../Settings.json"
import CustomizedSnackbars from '../../../Snackbar/Snackbar'

function RemoveCar() {

  const { user } = useAuth()

  const [responseMessage, setResponseMessage] = useState()
  const [messageType, setMessageType] = useState()
  const [snackBarOpen, setSnackBarOpen] = useState(false)
  const [key, setKey] = useState(0)

  const [carID, setCarID] = useState(0)

  const opensnackbar = (type, message) => {
    setSnackBarOpen(true)
    setMessageType(type)
    setResponseMessage(message)
    setKey(key + 1)
}

  const deleteCar = () => {
    fetch(Settings.Domain + Settings.DeleteCar, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: user.token,
        car_id: carID
      })
    })
    .then(response => {

      return response.json()
    })
    .then(data => {
      if (data.success) {
        opensnackbar("success", data.response)
      } else if (data.error) {
        opensnackbar("error", data.error)
      }
    })
    .catch(error => {
      console.log(error);
    })
  }

  return (
    <div className='container' style={{ margin: 20, height: "fit-content" }}>
      <div className='search-car-label'>
        <TextField fullWidth label="Car ID"
            value={carID}
            onChange={(e) => { setCarID(e.target.value) }}
          />
          <br></br>
          <Button color="primary" variant="outlined" style={{ margin: "auto", width: "250px", height: "50px" }} onClick={deleteCar}>Delete</Button>
        </div>
      <CustomizedSnackbars messageType={messageType} message={responseMessage} openSnackbar={snackBarOpen} key={key} />
    </div>
  )
}

export default RemoveCar