import React, { useEffect, useState } from 'react';
import { DataGrid } from "@mui/x-data-grid"

import Settings from '../../Settings.json';

import { useAuth } from "../../AuthProvider/AuthProvider"

import { Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, FormControl, InputLabel, MenuItem, Select, TextField, OutlinedInput, InputAdornment } from '@mui/material';
import CustomizedSnackbars from '../../Snackbar/Snackbar';

function Books() {

  const [responseMessage, setResponseMessage] = useState()
  const [messageType, setMessageType] = useState()
  const [snackBarOpen, setSnackBarOpen] = useState(false)
  const [key, setKey] = useState(0)

  const { user } = useAuth()

  const [orders, setOrders] = useState([])
  const [carList, setCarList] = useState([])

  const [carData, setCarData] = useState([])

  const [carPlaces, setCarPlaces] = useState([])

  const [modalOpen, setModalOpen] = useState(false)

  const [orderStatus, setOrderStatus] = useState("QUEUE")
  const [orderStatusSelectionOpen, setOrderStatusSlectionOpen] = useState(false)

  const [modalData, setModalData] = useState({})
  const [itemsList, setItemList] = useState([])
  const [itemModalOpen, setItemModalOpen] = useState(false)

  const [carDiscounts, setCarDiscounts] = useState([])

  const columns = [
    { field: 'id', headerName: 'Order ID', width: 70 },
    { field: 'car_name', headerName: 'Car name', width: 130 },
    { field: 'pickUp', headerName: 'Pick Up', width: 130 },
    { field: 'dropOff', headerName: 'Drop Off', width: 130 },
    {
      field: 'type',
      headerName: 'Type',
      width: 130,
      renderCell: (params) => getOrderType(params.row.type) // Call the getOrderType function
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 130,
      renderCell: (params) => (
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            loadOrderModal(params.row.id);
            setModalOpen(true)
          }}
        >
          View
        </Button>
      ),
    },
  ];

  const [rows, setRows] = useState([])

  const loadCarDiscounts = () => {
    fetch(Settings.Domain + Settings.GetCarDiscounts, {
      method: "GET",
    })
      .then((response) => {
        return response.json();
      })
      .then(data => {
        if (data.success) {
          setCarDiscounts(data.response)
        }
      })
      .catch(err => {
        console.log(err);
      })
  }

  const opensnackbar = (type, message) => {
    setSnackBarOpen(true)
    setMessageType(type)
    setResponseMessage(message)
    setKey(key + 1)
  }

  const getOrderPrice = () => {
    var service = 0

    if (modalData.order.pickUp != modalData.order.dropOff) {
      service = 80
    }

    const carPlaces = JSON.parse(modalData.carData.car_data.car_places)

    const placeFound = carPlaces.find((data, index) => data.id == modalData.order.pickUp)

    if (placeFound.amount === 0) {
      service = 80
    }

    var rentalPrice = modalData.carData.car.car_price * modalData.order.duretation

    for (var i = 0; i < carDiscounts.length; i++) {
      const selectedDiscount = carDiscounts[i]

      if (modalData.order.duretation >= selectedDiscount.min_day && modalData.order.duretation <= selectedDiscount.max_day) {
        rentalPrice = (rentalPrice) - ((rentalPrice) * (selectedDiscount.amount / 100))
        break
      } else if (selectedDiscount.max_day === ">") {
        rentalPrice = (rentalPrice) - ((rentalPrice) * (selectedDiscount.amount / 100))
        break
      }
    }

    var itemsPrice = 0

    if (modalData.items) {
      modalData.items.forEach((item) => {
        itemsPrice = itemsPrice + item.item_price * item.amount
      })
    }

    return {
      itemsPrice: itemsPrice,
      rentalPrice: rentalPrice,
      service_fee: service,
      car_price: modalData.carData.car.car_price,
      totalDays: modalData.order.duretation
    }
  }

  const getOrderType = (id) => {
    if (id === 0) {
      return <Chip label="QUEUE" variant="outlined" />;
    } else if (id === 1) {
      return <Chip label="ACCEPTED" color="success" variant="outlined" />;
    } else if (id === 2) {
      return <Chip label="PROGRESS" color="primary" variant="outlined" />;
    } else if (id === 3) {
      return <Chip label="COMPLETED" color="warning" variant="outlined" />;
    } else if (id === 4) {
      return <Chip label="CANCELED" color="error" variant="outlined" />;
    }
  }


  const getItemByID = (id) => {
    const itemFound = itemsList.find((item) => item.id === id)

    if (itemFound) {
      return itemFound
    }

    return null
  }

  const loadOrderModal = (order_id) => {
    const orderData = orders.find((order) => order.order.order_id == order_id)

    if (!orderData) return

    const carFound = findCar(orderData.order.car_id);

    if (!carFound) return

    setModalData({
      order: orderData.order,
      items: orderData.items,
      carData: carFound
    })
  }

  const loadItems = () => {
    fetch(Settings.Domain + Settings.LoadCarItem, {
      method: "GET",
    })
      .then(response => {
        return response.json()
      })
      .then(data => {
        if (data.success) {
          setItemList(data.response)
        }
      })
      .catch(error => {
        console.log(error);
      })
  }


  const getPlaceByID = (place_id) => {
    const foundPlace = carPlaces.find(place => place.id === place_id);
    const placeName = foundPlace ? foundPlace.place_name : null;

    return placeName
  }

  const findCar = (car_id) => {

    var car_data

    carList.forEach((carData) => {
      if (carData.car.car_data_id === car_id) {
        car_data = carData
      }
    })

    return car_data
  }

  const finalyzeRow = () => {

    setRows([])

    orders.map((order, index) => {

      const carFound = findCar(order.order.car_id);

      if (carFound) {
        carFound.order = order

        setCarData((oldData) => [...oldData, carFound]);

        const newRow = {
          id: order.order.order_id,
          car_name: carFound.car.car_name,
          pickUp: getPlaceByID(order.order.pickUp),
          dropOff: getPlaceByID(order.order.dropOff),
          type: order.order.type,
          action: "button"
        }

        setRows((oldData) => [...oldData, newRow]);
      }
    });


    setRows((oldData) => {
      return oldData.reverse()
    })
  }

  const loadCars = () => {
    fetch(Settings.Domain + Settings.GetCars, {
      method: 'GET',
    })
      .then(response => {
        return response.json()
      })
      .then(data => {
        if (data.success) {

          const response = data.response

          response.cars.forEach((car) => {
            const carData = response.car_data.find((carData) => carData.car_id === car.car_data_id)

            const carDataSet = {
              car: car,
              car_data: carData,
            };

            setCarList((oldData) => [...oldData, carDataSet]);
          })
        }
      })
  }

  const loadOrders = async () => {
    try {
      const response = await fetch(Settings.Domain + Settings.GetOrders, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token: user.token,
          page: 1,
          itemPerPage: 10
        }),
      });

      const data = await response.json();

      if (data.success) {
        const orders = data.response.map(order => {
          const orderItemsList = JSON.parse(order.items);

          const itemList = orderItemsList.map(item => {
            const itemFound = getItemByID(item.id);

            return itemFound ? {
              id: itemFound.id,
              item_name: itemFound.item_name,
              item_price: itemFound.item_price,
              amount: item.amount
            } : null;
          }).filter(Boolean);

          return {
            order: order,
            items: itemList
          };
        });

        setOrders(orders);
      }
    } catch (error) {
      console.error("Error loading orders:", error);
    }
  }

  const loadCarPlaces = () => {
    fetch(Settings.Domain + Settings.GetPlaces, {
      method: 'GET',
    })
      .then(response => {

        return response.json()
      })
      .then(data => {
        if (data.success) {
          setCarPlaces(data.response)
        }
      })
  }

  const saveOrder = () => {
    fetch(Settings.Domain + Settings.UpdateOrder, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        token: user.token,
        order_id: modalData.order.order_id,
        order_status: orderStatus
      }),
    })
      .then(response => {

        return response.json()
      })
      .then(data => {
        if (data.success) {
          opensnackbar("success", data.response)
        } else if (data.error) {
          opensnackbar("error", data.error)
        }
      })
      .catch(err => {
        console.log(err);
      })
  }

  useEffect(() => {
    loadItems();
    loadCarDiscounts();
    loadCarPlaces();
    loadOrders();
  }, []);

  useEffect(() => {
    loadCars();
  }, [orders])

  useEffect(() => {
    finalyzeRow();
  }, [carList])



  return (
    <div style={{ width: "100%" }}>
      <div style={{ background: "white", width: 'fit-content', maxWidth: "100%", margin: "auto" }}>
        <div style={{ maxHeight: 400, width: '100%', height: "fit-content" }}>
          <DataGrid
            style={{ width: "100%", margin: "auto" }}
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10]}
            isRowSelectable={false}
            disableRowSelectionOnClick={true}
          />
        </div>
      </div>
      <Dialog
        fullWidth
        open={modalOpen}>
        {modalData.order && (
          <DialogContent style={{ display: "flex", flexDirection: "column" }}>

            <DialogContentText>
              ORDER #{modalData.order.order_id} {getOrderType(modalData.order.type)}
            </DialogContentText>
            <br></br>
            <TextField
              value={modalData.carData.car.car_name}
              label="Name"
              disabled
              variant="standard"
            />
            <br></br>
            <TextField
              value={getPlaceByID(modalData.order.pickUp)}
              label="Pick Up Location"
              disabled
              variant="standard"
            />
            <br></br>
            <TextField
              value={getPlaceByID(modalData.order.dropOff)}
              label="Drop Off Location"
              disabled
              variant="standard"
            />
            <br></br>
            <TextField
              value={modalData.order.start_date}
              label="Pick Date"
              disabled
              variant="standard"
            />
            <br></br>
            <TextField
              value={modalData.order.end_date}
              label="Drop Date"
              disabled
              variant="standard"
            />
            <br></br>
            <TextField
              value={modalData.order.phone}
              label="Phone"
              disabled
              variant="standard"
            />
            <br></br>
            <TextField
              value={modalData.order.email}
              label="Email"
              disabled
              variant="standard"
            />
            <br></br>
            <TextField
              value={getOrderPrice().service_fee + user.currency}
              label="Service Fee"
              disabled
              variant="standard"
            />
            <br></br>
            <TextField
              value={getOrderPrice().rentalPrice + user.currency}
              label="Rental Price"
              disabled
              variant="standard"
            />
            <br></br>
            <TextField
              value={getOrderPrice().itemsPrice + user.currency}
              label="Item Price"
              disabled
              variant="standard"
            />
            <br></br>
            <TextField
              value={getOrderPrice().service_fee + getOrderPrice().rentalPrice + getOrderPrice().itemsPrice + user.currency}
              label="Total Price"
              disabled
              variant="standard"
            />
            <br></br>
            <TextField
              value={modalData.order.message}
              label="Message"
              disabled
              variant="standard"
            />
            <br></br>
            <Select
              open={orderStatusSelectionOpen}
              onClose={() => { setOrderStatusSlectionOpen(false) }}
              onOpen={() => { setOrderStatusSlectionOpen(true) }}
              value={orderStatus}
              label="Order Status"
              onChange={(e) => { setOrderStatus(e.target.value) }}
              variant="standard"
            >
              {["QUEUE", "ACCEPTED", "PROGRESS", "COMPLETED", "CANCELED"].map((place, index) => (
                <MenuItem value={place} key={index}>
                  {place}
                </MenuItem>
              ))}
            </Select>

            <br></br>

            <Button color="primary" variant="outlined" sx={{ height: 80 }} onClick={() => {
              setItemModalOpen(true)
            }}>Item List</Button>


          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={(e) => {
            saveOrder()
            setModalOpen(false);
          }}>Save</Button>
          <Button onClick={(e) => {
            setModalOpen(false);
          }}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth
        open={itemModalOpen}>
        {modalData.items && (
          <DialogContent style={{ display: "flex", flexDirection: "column" }}>
            {
              modalData.items.map((item, index) => (
                <FormControl sx={{ m: 1, paddingRight: 0 }} variant="outlined" fullWidth key={index}>
                  <InputLabel>{item.item_name}</InputLabel>
                  <OutlinedInput
                    fullWidth
                    disabled
                    style={{ paddingRight: "0", border: "none" }}
                    type={"text"}
                    endAdornment={
                      <InputAdornment position="end">
                        <InputLabel sx={{ width: "100px" }}>{item.item_price + user.currency}</InputLabel>
                        <OutlinedInput
                          style={{ paddingRight: "0", border: "none" }}
                          type='tel'
                          disabled
                          value={item.amount}
                        />
                      </InputAdornment>
                    }
                  />
                </FormControl>
              ))
            }
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={(e) => {
            setItemModalOpen(false);
          }}>Close</Button>
        </DialogActions>
      </Dialog>
      <CustomizedSnackbars messageType={messageType} message={responseMessage} openSnackbar={snackBarOpen} key={key} />
    </div>
  )
}

export default Books