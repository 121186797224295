import React, { createContext, useState, useContext, useEffect } from 'react';
import Cookies from 'js-cookie';
import Settings from "../Settings.json"
import { useLocation } from 'react-router-dom';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {

  const token = Cookies.get('token')

  const [colors, setColors] = useState({
    primaryColor: "rgb(27, 146, 39)",
    secondaryColor: "rgb(19, 105, 28)"
  })

  const [user, setUser] = useState({
    loggedIn: false,
    verified: false,
    orders: [],
    email: "",
    firstName: "",
    lastName: "",
    memberShip: "",
    token: "",
    phone: 0,
    loaded: false,
    primaryColor: colors.primaryColor,
    secondaryColor: colors.secondaryColor,
    currency: "€"
  });

  const login = (token) => {
    Cookies.set('token', token);
    verifyUser(token)
  };

  const logout = () => {
    setUser({
      loggedIn: false,
      verified: false,
      orders: [],
      email: "",
      firsName: "",
      lastName: "",
      token: "",
      phone: 0,
      loaded: true,
      primaryColor: colors.primaryColor,
      secondaryColor: colors.secondaryColor,
      currency: "€"
    });
    Cookies.remove('token');
  };

  const getColors = () => {
    fetch(Settings.Domain + Settings.GetColors, {
      method: "GET"
    })
      .then(response => {
        return response.json()
      })
      .then(data => {
        if (data.success) {
          const colors = data.response;

          var primaryColor
          var secondaryColor

          colors.forEach((color) => {
            if (color.color_type === "primary_color") {
              primaryColor = color.color_code;
            }
            if (color.color_type === "secondary_color") {
              secondaryColor = color.color_code;
            }
          });

          setColors({
            primaryColor: primaryColor,
            secondaryColor: secondaryColor
          })
        }
        if (data.error) {
          // Handle any error if needed
        }
      })
      .catch(err => {
        console.log(err);
      })
  }

  const verifyUser = (token) => {
    fetch(Settings.Domain + Settings.VerifyUser, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: token,
      })
    })
      .then(response => {
        return response.json()
      })
      .then(data => {

        if (data.success == true) {
          var data = data.response
          setUser({
            loggedIn: true,
            verified: data.verified === 1,
            orders: data.orders || [],
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName,
            memberShip: data.memberShip,
            token: token,
            phone: data.phone || "",
            loaded: true,
            primaryColor: colors.primaryColor,
            secondaryColor: colors.secondaryColor,
            currency: "€"
          })
        } else {
          logout()
          return
        }
      })
      .finally(() => {
        getColors()
      })
      .catch(err => {
        console.log(err);
      })
  }

  useEffect(() => {
    verifyUser(token)
  }, [])



  useEffect(() => {

    const updatedUser = { ...user };

    updatedUser.primaryColor = colors.primaryColor
    updatedUser.secondaryColor = colors.secondaryColor

    setUser(updatedUser)
  }, [colors])

  useEffect(() => {
    if (user.loaded) {
      document.documentElement.style.setProperty('--primary-color', user.primaryColor);
      document.documentElement.style.setProperty('--secondary-color', user.secondaryColor);
    }
  }, [user.loaded, user.primaryColor, user.secondaryColor])

  return (
    <AuthContext.Provider value={{ user, login, logout, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
