import React, { useState } from 'react'

import Settings from "../../../Settings.json"
import { TextField, Button } from '@mui/material'
import CustomizedSnackbars from '../../../Snackbar/Snackbar'

function DeleteExpedition() {

    const [responseMessage, setResponseMessage] = useState()
    const [messageType, setMessageType] = useState()
    const [snackBarOpen, setSnackBarOpen] = useState(false)
    const [key, setKey] = useState(0)

    const opensnackbar = (type, message) => {
        setSnackBarOpen(true)
        setMessageType(type)
        setResponseMessage(message)
        setKey(key + 1)
    }

    const [expeditionID, setExpeditionID] = useState()

    const deleteExpedition = () => [
        fetch(Settings.Domain + Settings.DeleteExpedition, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                id: expeditionID
            })
        })
        .then(response => {
            return response.json()
        })
        .then(data => {
            if (data.success) {
                opensnackbar("success", data.response)
            }
            if (data.error) {
                opensnackbar("error", data.error)
            }
        })
        .catch(error => {
            console.log(error);
        })
    ]

    return (
        <div className='search-car-label'>
            <TextField
                fullWidth
                label="Expedition ID"
                value={expeditionID}
                onChange={(e) => { setExpeditionID(e.target.value) }}
            />
            <br></br>

            <Button color="primary" variant="outlined" style={{ margin: "auto", width: "250px", height: "50px" }} onClick={deleteExpedition}>Delete</Button>

            <CustomizedSnackbars messageType={messageType} message={responseMessage} openSnackbar={snackBarOpen} key={key} />
        </div>
    )
}

export default DeleteExpedition