import React, { useEffect, useState } from 'react'
import CircularProgress from '@mui/material/CircularProgress';

import "./Loader.css"
import { useAuth } from '../AuthProvider/AuthProvider';

function Loader() {

    const { user } = useAuth()

    const [showLoader, setShowLoader] = useState("flex")

    useEffect(() => {

        if (user.loaded === false) return

        const updater = setInterval(() => {
            setShowLoader(user.loaded ? "none" : "flex")

            clearInterval(updater)
        }, 200);

        
    }, [user.loaded])

    return (
        <div className='loader-background' style={{display: showLoader}}>
            <CircularProgress />
        </div>
    )
}

export default Loader